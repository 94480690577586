import React, { useState } from 'react';
import { connect } from 'react-redux';

// Components
import SearchBar from '../SearchBar';
import FilterToggle from './filterToggle';
import Logo from './logo';

// Utils
import { fireGaEvent, gaActions, gaCategories } from '../../utils/analytics';

// Styles
import {
  StyledHeader,
  StyledHeaderContent,
  StyledSearchButton,
} from './styles';

const Header = ({ searchLoading }) => {
  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = (toggle: boolean) => {
    setShowSearch(toggle);
    fireGaEvent(gaCategories.search, toggle ? gaActions.openSearch : gaActions.closeSearch, { label: 'From Nav' });
  };

  return (
    <>
      {showSearch && <SearchBar closeSearch={toggleSearch} />}
      <StyledHeader data-cy="header">
        <StyledHeaderContent>
          <StyledSearchButton
            onClick={() => toggleSearch(!showSearch)}
            data-cy="search"
          >
            {searchLoading ?
              <img
                role="presentation"
                src="/img/icons/search_loader.gif"
              /> :
              <img
                role="presentation"
                src="/img/icons/search.svg"
              />
            }
            <span>Search Menu</span>
          </StyledSearchButton>
          <Logo />
          <FilterToggle />
        </StyledHeaderContent>
      </StyledHeader>
    </>
  );
};

const mapStateToProps = state => ({
  searchLoading: state.search.loading,
});

export default connect(mapStateToProps)(Header);
