import styled from 'styled-components';

// Breakpoints
import { media } from '../../styles/breakpoints';

// Styles
import { COLORS, FONTS, LAYOUT } from '../../styles';
import { ContainerBlock, deriveMediaQueriesFromHeaderHeight } from '../../styles/mixins';

export const StyledHeader = styled.nav`
  background: ${COLORS.WHITE};
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  color: ${COLORS.RED};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  ${deriveMediaQueriesFromHeaderHeight('height: $s1$px;')}
`;

export const StyledHeaderContent = styled(ContainerBlock)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

export const StyledLogo = styled.img`
  width: 78px;

  ${media.tabletXLarge`
    width: 68px;
  `}

  ${media.desktopLarge`
    width: 128px;
  `}
`;

const HeaderButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${COLORS.RED};
  cursor: pointer;
  display: flex;
  font-family: ${FONTS};
  font-size: 16px;
  height: 100%;
  -webkit-text-stroke: 0.65px;

  > * {
    object-fit: contain;
  }

  > span {
    display: none;
  }

  ${media.tabletXLarge`
    > span {
      display: block;
    }
  `}
`;

export const StyledSearchButton = styled(HeaderButton)`
  margin-left: ${LAYOUT.MOBILE_GUTTER * -1}px;
  padding: 0 0 0 ${LAYOUT.MOBILE_GUTTER}px;

  img {
    margin-right: 10px;
    max-height: 20px;
    max-width: 20px;
  }

  ${media.tabletXLarge`
    margin-left: ${LAYOUT.GUTTER * -1}px;
    padding-left: ${LAYOUT.GUTTER}px;
  `}
`;

export const StyledFilterButton = styled(HeaderButton)`
  margin-right: ${LAYOUT.MOBILE_GUTTER * -1}px;
  padding: 0 ${LAYOUT.MOBILE_GUTTER}px 0 0;

  img {
    max-height: 20px;
    max-width: 20px;
  }

  ${media.tabletXLarge`
    margin-right: ${LAYOUT.GUTTER * -1}px;
    padding-right: ${LAYOUT.GUTTER}px;

    img {
      margin-right: 10px;
    }
  `}
`;
