import React from 'react';

// Styles
import { StyledBackToMenuLink } from './styles';

export const BackToMenu = ({ onClick }) => (
  <StyledBackToMenuLink
    aria-label="Navigate back"
    onClick={onClick}
    data-cy="back"
  >
    <img
      role="presentation"
      alt="Back button icon"
      src="/img/icons/back.svg"
    />
  </StyledBackToMenuLink>
);
