import { all } from 'redux-saga/effects';
import filtersSagas from './filters/saga';
import menuSagas from './menu/saga';
import searchSagas from './search/saga';

export default function* rootSaga(getState: any) {
  yield all([
    filtersSagas(),
    menuSagas(),
    searchSagas(),
  ]);
}
