import React, { Component } from 'react';
import { compose } from 'redux';

// Natural Component
import { Welcome } from '../../components/Welcome';
// Handlers
import menuHandler from '../handlers/menuHandler';
import pageHandler from '../handlers/pageHandler';

type StoreProps = {
  setKioskMode: (arg: boolean) => void;
};

class SplashPage extends Component<StoreProps> {
  componentDidMount() {
    localStorage.setItem('kiosk', 'true');
  }
  render() {
    return (
      <Welcome />
    );
  }
}

export default compose(
  menuHandler,
  pageHandler,
)(SplashPage);
