// Config
import API from '../constants/api';
// Generic Service
import httpService from './generic.service';

/**
 * Fetch products
 */
export const fetchProducts = () => {
  const type = localStorage.getItem('kiosk') ? 'kiosk' : 'web';
  return httpService({
    method: 'GET',
    url: `${API.scheme}${API.url}${API.routes.menu}`,
    headers: {
      'KEY': `${API.key}`,
      'X-Type': `${type}`,
    },
  });
};
