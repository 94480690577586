import { darken } from 'polished';
import styled from 'styled-components';

// Styles
import { COLORS } from '../../styles';
// Breakpoints
import { media } from '../../styles/breakpoints';
import { ContainerBlock } from '../../styles/mixins';

export const StyledLayout = styled(ContainerBlock)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const StyledFooterContainer = styled.ul`
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;

  a {
    color: ${COLORS.RED};
    :hover {
      color: ${darken(0.075, COLORS.RED)};
    }
  }

  li {
    display: block;
    margin: 5px 0;
  }

  ${media.tabletXLarge`
    li {
      display: inline-block;
      color: ${COLORS.RED};
      margin: 5px 20px;
      &:after {
        content: '\\2022';
        position: relative;
        top: 1px;
        right: -20px;
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
    }
  `}
`;

export const StyledCopyright = styled.div`
  font-size: 12px;
  padding-top: 10px;
  text-align-last: center;
  color: ${COLORS.GRAY};
  line-height: 1.7;
  ${media.desktopLarge`
    max-width: 1000px;
    margin: 0 auto;
  `}
`;
