const ENV_CONFIG = {
  local: {
    REACT_APP_API_BASE_URL: 'dev.api.pret.fuzzhq.com',
    REACT_APP_API_KEY: 'BH81VT6S5UB8TP5DNPTD',
    REACT_APP_API_VERSION: 'v1',
    REACT_APP_API_SCHEME: 'https://',
    REACT_APP_FRONTEND_BASE_URL: 'localhost:3000',
    REACT_APP_FRONTEND_SCHEME: 'http://',
  },
  dev: {
    REACT_APP_API_BASE_URL: 'dev.api.pret.fuzzhq.com',
    REACT_APP_API_KEY: 'BH81VT6S5UB8TP5DNPTD',
    REACT_APP_API_VERSION: 'v1',
    REACT_APP_API_SCHEME: 'https://',
    REACT_APP_FRONTEND_BASE_URL: 'dev.app.pret.fuzzhq.com',
    REACT_APP_FRONTEND_SCHEME: 'http://',
  },
  staging: {
    REACT_APP_API_BASE_URL: 'staging.api.pret.fuzzhq.com',
    REACT_APP_API_KEY: 'JGMTm5Na5ql22NIyyxot',
    REACT_APP_API_VERSION: 'v1',
    REACT_APP_API_SCHEME: 'https://',
    REACT_APP_FRONTEND_BASE_URL: 'staging.app.pret.fuzzhq.com',
    REACT_APP_FRONTEND_SCHEME: 'http://',
  },
  production: {
    REACT_APP_API_BASE_URL: 'api.product.pret.co.uk',
    REACT_APP_API_KEY: 'Oq53ibYzoFq2pHffMyFV',
    REACT_APP_API_VERSION: 'v1',
    REACT_APP_API_SCHEME: 'https://',
    REACT_APP_FRONTEND_BASE_URL: 'app.pret.fuzzhq.com',
    REACT_APP_FRONTEND_SCHEME: 'http://',
  },
};

const currentEnv = (process.env.REACT_APP_STATIC_ENV ? process.env.REACT_APP_STATIC_ENV : 'local');

export default ENV_CONFIG[currentEnv];
