import React from 'react';
import { connect } from 'react-redux';

// Styles
import { StyledSearchStatus } from './styles';

type StoreProps = {
  searchLoading: boolean;
  searchResults: any[];
};
type PassProps = {
  // TODO - extract into type
  goBack: () => void;
  searchTerm: string;
};

const SearchStatus = ({ goBack, searchLoading, searchResults, searchTerm }: PassProps & StoreProps) => {
  return (
    <StyledSearchStatus>
      <button
        aria-label="Navigate back"
        onClick={goBack}
        data-cy="back"
      >
        <img
          src="/img/icons/back.svg"
          role="presentation"
          alt="Back button icon"
        />
      </button>
      <p data-cy="search-status">
        {searchLoading ? 'Searching for ' : ''}
        {(!searchLoading && searchResults.length) ? 'Showing results for ' : ''}
        {(!searchLoading && !searchResults.length) ? 'No results for ' : ''}
        <i>{searchTerm}</i>
      </p>
    </StyledSearchStatus>
  );
};

const mapStateToProps = state => ({
  searchLoading: state.search.loading,
  searchResults: state.search.results,
});

export default connect(mapStateToProps)(SearchStatus);
