import React, { Component } from 'react';
import { compose } from 'redux';

// Natural Components
import FilterOverlay from '../../components/FilterOverlay';
import FilterForm from '../../components/FilterOverlay/form';
import FilterStatus from '../../components/FilterStatus';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MenuGrid from '../../components/MenuGrid';
import SubNav from '../../components/SubNav';
import TopNav from '../../components/TopNav';
// Handlers
import menuHandler from '../handlers/menuHandler';
import pageHandler from '../handlers/pageHandler';

type PassProps = {
  match: {
    // TODO - extract into type
    params: {
      [key: string]: string;
    };
  };
  menuLoading: boolean;
  menuData: any;
};
class Menu extends Component<PassProps> {
  render() {
    return (
      <>
        <Header />
        <TopNav matchParams={this.props.match.params} />
        <SubNav matchParams={this.props.match.params} />
        <FilterStatus />
        <MenuGrid menu={this.props.menuData} menuLoading={this.props.menuLoading} matchParams={this.props.match.params} />
        <FilterOverlay>
          <FilterForm />
        </FilterOverlay>
        {this.props.menuData.length > 0 && !this.props.menuLoading && localStorage.getItem('kiosk') === null && <Footer />}
      </>
    );
  }
}

export default compose(
  menuHandler,
  pageHandler,
)(Menu);
