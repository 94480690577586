import styled from 'styled-components';

// Breakpoints
import { media } from '../../styles/breakpoints';

// Globals
import { COLORS, FONTS } from '../../styles';
import { ContainerBlock } from '../../styles/mixins';

export const StyledSearchStatus = styled(ContainerBlock)`
  background: white;
  display: flex;
  width: 100%;

  p {
    flex: 1;
    font-family: ${FONTS.PRIMARY};
    font-size: 18px;
    margin: 0;
    padding: 20px 0;

    ${media.tabletXLarge`
      font-size: 32px;
    `}
  }

  button {
    background: none;
    border: none;
    color: ${COLORS.RED};
    cursor: pointer;
    padding: 0 20px 0 0;
  }
`;
