import styled from 'styled-components';

// Globals
import { COLORS, FONTS, LAYOUT } from '../../styles';
import { media } from '../../styles/breakpoints';
import { ContainerBlock, NavButton } from '../../styles/mixins';

export const StyledOccasionLink = styled(NavButton)`
  align-items: center;
  color: ${COLORS.GRAY};
  display: flex;
  flex-shrink: 0;
  font-family: ${FONTS.PRIMARY};
  font-size: 24px;
  font-style: normal;
  height: 100%;
  padding-right: 52px;

  ${media.tabletXLarge`
    padding-right: 52px;
  `}

  ${media.desktopLarge`
    padding-right: 75px;
  `}
`;

export const StyledContent = styled.div`
  align-items: center;
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
`;

export const StyledContainer = styled(ContainerBlock)`
  background: ${COLORS.WHITE};
  height: ${LAYOUT.NAV.M_HEIGHT}px;

   overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    height: 0px;  /* remove scrollbar space */
    background: transparent;
  }

  -ms-overflow-style: -ms-autohiding-scrollbar;

  ${media.tabletXLarge`
    height: ${LAYOUT.NAV.T_HEIGHT}px;
  `}

  ${media.desktopLarge`
    height: ${LAYOUT.NAV.D_HEIGHT}px;
  `}
`;
