// Config
import API from '../constants/api';

// Generic Service
import httpService, { URIEncode } from './generic.service';

/**
 * Filter products
 *
 */
export const filterProducts = (args: any) => {
  console.log('args in filterProducts service', args);
  const type = localStorage.getItem('kiosk') ? 'kiosk' : 'web';
  const query = URIEncode(args);
  return httpService({
    method: 'GET',
    url: `${API.scheme}${API.url}${API.routes.menu}?${query}`,
    headers: {
      'KEY': `${API.key}`,
      'X-Type': `${type}`,
    },
  });
};
