import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

type PassProps = {
  history: any;
};
type StoreProps = {
  push: (path: string) => void;
};
export default function pageHandler(WrappedComponent: any) {
  class PageComponent extends React.Component<StoreProps & PassProps> {
    componentDidMount() {
      this.transitionPage();
    }

    componentDidUpdate() {
      // TODO - only fire if route has changed
      this.transitionPage();
    }

    transitionPage = () => {
      // Ensure user is scrolled to top of view
      window.scrollTo(0, 0);
    }

    goBackOrRedirect = () => {
      this.props.history.goBack();

      // TODO document.referred isn't reliably working on the test envs
      // Go back if same domain, otherwise, push home.
      // if (document.referrer.split('/')[2] !== window.location.host) {
      //   this.props.push(routes.menu.base);
      // } else {
      //   this.props.history.goBack();
      // }
    }

    render() {
      return (
        <WrappedComponent
          goBackOrRedirect={this.goBackOrRedirect}
          {...this.props}
        />
      );
    }
  }

  const mapDispatchToProps = (dispatch: any) => ({
    push: (path: string) => dispatch(push(path)),
  });

  return connect(null, mapDispatchToProps)(PageComponent);
}
