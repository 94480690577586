import styled from 'styled-components';

// Breakpoints
import { media } from '../../styles/breakpoints';

// Globals
import { COLORS, LAYOUT } from '../../styles';

export const StyledBackToMenuLink = styled.button`
  background: ${COLORS.WHITE};
  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 50%;
  color: ${COLORS.RED};
  cursor: pointer;
  display: block;
  height: 30px;
  left: ${LAYOUT.MOBILE_GUTTER}px;
  padding: 0px;
  position: absolute;
  top: ${LAYOUT.MOBILE_GUTTER}px;
  width: 30px;

  img {
    position: relative;
    top: 2px;
    left: -2px;
  }

  ${media.tabletXLarge`
    align-items: center;
    border-radius: inherit;
    box-shadow: none;
    display: flex;
    height: 60px;
    justify-content: left;
    margin: 10px 0;
    padding-left: 10px;
    position: static;
    width: 60px;

    img {
      position: static;
    }
  `}
`;
