import { darken, lighten } from 'polished';
import styled from 'styled-components';

// Styles
import { COLORS } from '../../styles';

export const StyledTag = styled.button`
  border: 1px solid ${COLORS.RED};
  border-radius: 4px;
  background: ${(props: { isSelected?: boolean }) => props.isSelected ? COLORS.RED : COLORS.WHITE};
  color: ${(props: { isSelected?: boolean }) => props.isSelected ? COLORS.WHITE : COLORS.RED};
  cursor: pointer;
  font-size: 16px;
  min-height: 48px;
  padding: 5px;
  -webkit-text-stroke: 0.65px;

  &:hover {
    background: ${(props: { isSelected?: boolean }) => props.isSelected ? `${darken(0.025, COLORS.RED)}` : COLORS.WHITE};
    color: ${(props: { isSelected?: boolean }) => props.isSelected ? COLORS.WHITE : `${lighten(0.075, COLORS.RED)}`};
    border: ${(props: { isSelected?: boolean }) => props.isSelected ? `1px solid ${COLORS.RED}` : `1px solid ${lighten(0.075, COLORS.RED)}`};
  }
`;
