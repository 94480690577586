import styled from 'styled-components';

// Globals
import { COLORS, FONTS, LAYOUT } from '../../styles';
import { media } from '../../styles/breakpoints';
import { ContainerBlock, NavButton } from '../../styles/mixins';

export const StyledCategoryLink = styled(NavButton)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-family: ${FONTS.PRIMARY};
  font-size: 18px;
  color: ${COLORS.GRAY};
  height: 100%;
  padding-right: 35px;

  ${media.tabletXLarge`
    padding-right: 45px;
  `}

  ${media.desktopLarge`
    padding-right: 75px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  position: sticky;
  top: ${LAYOUT.NAV.M_HEIGHT}px;

  ::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    height: 0px;  /* remove scrollbar space */
    background: transparent;
  }

  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-image:
    linear-gradient(to right, white, white),
    linear-gradient(to left, white, white),
    linear-gradient(to right, rgba(0,0,0,.03), rgba(255,255,255,0)),
    linear-gradient(to left, rgba(0,0,0,.03), rgba(255,255,255,0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 30px 100%, 30px 100%, 15px 100%, 15px 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
`;

export const ContainerWrapper = styled(ContainerBlock)`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: ${LAYOUT.SUBNAV.M_HEIGHT}px;
  position: sticky;
  top: ${LAYOUT.NAV.M_HEIGHT}px;

  ::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    height: 0px;  /* remove scrollbar space */
    background: transparent;
  }

  ${media.tabletXLarge`
    height:${LAYOUT.SUBNAV.T_HEIGHT}px;
    position: static;
  `}

  ${media.desktopLarge`
    height:${LAYOUT.SUBNAV.D_HEIGHT}px;
  `}
`;
