const globalActions = {

  FILTER_OVERLAY_VISIBLE: '/pret-allergens/global/FILTER_OVERLAY_VISIBLE',
  toggleFilterOverlay: (visible: boolean) => ({
    type: globalActions.FILTER_OVERLAY_VISIBLE,
    visible,
  }),

  FILTERS_APPLIED: '/pret-allergens/global/FILTERS_APPLIED',
  filtersApplied: (applied: boolean) => ({
    type: globalActions.FILTERS_APPLIED,
    applied,
  }),

  SEARCH_VISIBLE: '/pret-allergens/global/SEARCH_VISIBLE',
  toggleSearch: (visible: boolean) => ({
    type: globalActions.SEARCH_VISIBLE,
    visible,
  }),
};

export default globalActions;
