import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';

/*
  * Type Application state
*/
export interface ApplicationState {
  filters: FiltersState;
  global: GlobalState;
  menu: MenuState;
  router: RouterState;
  search: SearchState;
}

/*
  * Import reducers and reducer types
*/
import { filtersReducer, ReducerState as FiltersState } from './filters/reducer';
import { globalReducer, ReducerState as GlobalState } from './global/reducer';
import { menuReducer, ReducerState as MenuState} from './menu/reducer';
import { ReducerState as SearchState, searchReducer } from './search/reducer';

// Combines all reducers to a single reducer function
const rootReducer = history => combineReducers<ApplicationState>({
  filters: filtersReducer,
  global: globalReducer,
  menu: menuReducer,
  router: connectRouter(history),
  search: searchReducer,
});

export default rootReducer;
