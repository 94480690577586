const searchActions = {
  SEARCH_MENU: '/pret-allergens/search/SEARCH_MENU',
  searchMenu: (args: object) => ({
    type: searchActions.SEARCH_MENU,
    args,
  }),

  SET_SEARCH_RESULTS: '/pret-allergens/search/SET_SEARCH_RESULTS',
  setSearchResults: (value, results) => ({
    type: searchActions.SET_SEARCH_RESULTS,
    value,
    results,
  }),
};

export default searchActions;
