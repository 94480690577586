import { lighten } from 'polished';
import styled from 'styled-components';

// Styles
import { COLORS, FONTS, LAYOUT } from '../../styles';
// Breakpoints
import { media } from '../../styles/breakpoints';
import { ContainerBlock, deriveMediaQueriesFromHeaderHeight } from '../../styles/mixins';
// Component
import Tag from '../Tag';

export const StyledApplyButton = styled.button`
  background: ${COLORS.RED};
  border: none;
  border-radius: 4px;
  color: ${COLORS.WHITE};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 14px 5px;
  width: 100%;
  -webkit-text-stroke: 0.65px;
  #label {
    padding: 3px 0;
  }
  img {
    padding-right: 5px;
  }
`;

export const StyledClearFiltersButton = styled.button`
  background: ${COLORS.WHITE};
  border: none;
  color: ${COLORS.RED};
  cursor: pointer;
  font-weight: bold;
  padding: 30px 5px;
  width: 100%;
  &:hover {
    color: ${lighten(0.075, COLORS.RED)};
    text-decoration: underline;
  }
`;

export const StyledButtonsContainer = styled.div`
  margin-top: auto;
  button {
    min-height: ${(props: { kiosk: string | null }) => props.kiosk ? '80px' : '38px'};
  }
`;

export const StyledOverlay = styled.div`
  background: ${COLORS.WHITE};
  height: 100%;
  left: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const StyledOverlayContent = styled(ContainerBlock)`
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;

  ${media.tabletXLarge`
    padding-top: 0px;
    height: calc(100% - ${LAYOUT.HEADER.T_HEIGHT}px);
    form {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
  `}

  ${media.desktopLarge`
    height: calc(100% - ${LAYOUT.HEADER.D_HEIGHT}px);
  `}

  h1, h2 {
    font-family: ${FONTS.PRIMARY_FONT_FAMILY};
    font-weight: bold;
  }

  h1 {
    font-size: 24px;
    margin: 0 0 20px;

    ${media.tabletXLarge`
      font-size: 36px;
    `}

    ${media.desktopLarge`
      font-size: 42px;
      margin-bottom: 40px;
    `}
  }

  h2 {
    font-size: 18px;

    ${media.tabletXLarge`
      font-size: 20px;
    `}

    ${media.desktopLarge`
      font-size: 24px;
    `}
  }

  h3 {
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
    -webkit-text-stroke: 0.65px;
    ${media.tabletXLarge`
      font-size: 18px;
    `}
    ${media.desktopLarge`
      margin-top: 20px;
    `}
  }
`;

export const StyledOverlayHeader = styled(ContainerBlock)`
  background: white;
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1;

  ${deriveMediaQueriesFromHeaderHeight('height: $s1$px;')}

  ${media.tabletXLarge`
    border-bottom: 1px solid transparent;
  `}
`;

export const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  width: 100%;
  ${(props: { kiosk: string | null }) => props.kiosk ? media.tabletXLarge`
    padding-bottom: 150px` : media.tabletXLarge`
    padding-bottom: 60px;
  `}
`;

export const StyledCloseButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: ${COLORS.RED};
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  font-size: 16px;
  justify-content: space-between;
  padding: 0;
  -webkit-text-stroke: 0.65px;
  width: 100%;

  ${media.tabletXLarge`
    flex-direction: row;
    width: auto;

    span {
      padding-left: 10px;
    }
  `}
`;

export const StyledTag = styled(Tag)`
  flex-basis: calc(50% - ${LAYOUT.MOBILE_GUTTER / 2}px);
  max-width: calc(50% - ${LAYOUT.MOBILE_GUTTER / 2}px);
  margin: 0 ${LAYOUT.MOBILE_GUTTER}px ${LAYOUT.MOBILE_GUTTER}px 0;

  &:nth-child(2n){
    margin-right: 0;
  }

  ${media.tabletXLarge`
    flex-basis: calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    margin: 0 30px 20px 0;

    &:nth-child(2n){
      margin-right: 30px;
    }

    &:nth-child(3n){
      margin-right: 0px;
    }
  `}

  ${(props: { kiosk?: string | null }) => props.kiosk ? (
    media.desktopLarge`
      flex-basis: calc(33.33% - 20px);
      max-width: calc(33.33% - 20px);
      min-height: 80px;
      margin: 0 30px 20px 0;

      &:nth-child(2n){
        margin-right: 30px;
      }

      &:nth-child(3n){
        margin-right: 0px;
      }
    `) : (
    media.desktopLarge`
      flex-basis: calc(25% - 15px);
      max-width: calc(25% - 15px);
      margin: 0 20px 20px 0;

      &:nth-child(2n){
        margin-right: 20px;
      }

      &:nth-child(3n){
        margin-right: 20px;
      }

      &:nth-child(4n){
        margin-right: 0;
      }
    `)
  }
`;

export const StyledDisclaimerHolder = styled.div`
  width: 100%;
  background-color: rgba(64, 64, 64, 0.05);
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 12px;

  ${media.tabletXLarge`
    margin-top: 15px;
    margin-bottom: 15px;
  `}

  ${media.desktopLarge`
    margin-top: 15px;
    margin-bottom: 15px;
  `}

`;

export const StyledDisclaimerBody = styled.p`
  width: 100%;
  color: #404040;
  font-size: 12px;
  font-weight: bold;
  font-family: Interstate;
  line-height: 130%;

  ${media.tabletXLarge`
    font-size: 14px;
    line-height: 110%;
  `}

  ${media.desktopLarge`
    font-size: 14px;
    line-height: 110%;
  `}
`;

export const StyledDisclaimerHeader = styled.p`
  width: 100%;
  color: #404040;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  font-family: Interstate;
  margin-bottom: 0.5em;

  ${media.tabletXLarge`
    font-size: 16px;
  `}

  ${media.desktopLarge`
    font-size: 16px;
  `}
`;
