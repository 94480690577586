import React from 'react';

import { StyledCopyright, StyledFooterContainer, StyledLayout } from './styles';

interface Link {
  label: string;
  link: string;
}
const Links = [
  {
    label: 'Terms and Conditions',
    link: 'https://www.pret.co.uk/en-gb/terms-and-conditions',
  },
  {
    label: 'Privacy Policy',
    link: 'https://www.pret.co.uk/en-gb/privacy-policy',
  },
  {
    label: 'Main Site',
    link: 'https://www.pret.co.uk/en-gb',
  },
];

const Footer: React.SFC = () => (
  <StyledLayout data-cy="footer">
    <StyledFooterContainer data-cy="links">
      {Links.map((item: Link) => <li key={item.label}><a href={item.link}>{item.label}</a></li>)}
    </StyledFooterContainer>
    <StyledCopyright data-cy="copyright">
      © Pret A Manger 2019. Pret A Manger (Europe) Ltd, Reg. Number 1854213 · Pret A Manger (USA) Ltd, Reg. Number 3836164 · Pret A Manger (Hong Kong) Ltd, Reg. Number 4240964 The above three companies are registered in England and their registered office is at 75B, Verde, 10 Bressenden Place, London SW1E 5DH
    </StyledCopyright>
  </StyledLayout>
);

export default Footer;
