import styled from 'styled-components';

// Styles
import { COLORS, FONTS, LAYOUT } from '../../styles';
import { media } from '../../styles/breakpoints';
import { ContainerBlock } from '../../styles/mixins';

export const StyledContainer = styled(ContainerBlock)`
  color: ${COLORS.GRAY};
  font-family: ${FONTS.SECONDARY};
  position: relative;

  p {
    line-height: 1.63;
  }
`;

export const StyledTopLeftContainer = styled.div`
  width: 100%;

  #allergens {
    font-weight: bold;
    line-height: 1.33;
  }

  h1 {
    font-size: 32px;
    font-weight: normal;
    margin-top: 20px;
    -webkit-text-stroke: 0.65px;

    ${media.tabletXLarge`
      margin-top: 0px;
    `}
    ${media.desktopLarge`
      font-size: 52px;
    `}
  }

  p {
    margin-top: 20px;
  }

  ${(props: { kiosk: string | null }) => props.kiosk ? (
    media.tabletXLarge`
      width: 40%;
    `) : (
    media.tabletXLarge`
      width: 57%;
    `)
  }

  ${media.tabletXLarge`
    padding-right: 60px;
    p {
      font-size: 16px;
    }
  `}
  ${media.desktopLarge`
    padding-right: 100px;
    p {
      font-size: 18px;
    }
  `}
`;

export const StyledTopRightContainer = styled.div`
  margin-left: ${LAYOUT.MOBILE_GUTTER * -1}px;
  margin-right: ${LAYOUT.MOBILE_GUTTER * -1}px;

  ${media.tabletXLarge`
    margin-left: 0;
    margin-right: 0;
  `}

  ${(props: { kiosk: string | null }) => props.kiosk ? (
    media.tabletXLarge`
      width: 60%;
    `) : (
    media.tabletXLarge`
      width: 43%;
    `)
  }
`;

export const StyledTopContainer = styled.div`
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  padding-bottom: 35px;

  ${media.tabletXLarge`
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 48px;
  `}
`;

export const StyledBottomLeftContainer = styled.div`
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  padding-top: 35px;
  padding-bottom: 35px;
  p {
    font-size: 14px;
  }

  ${media.tabletXLarge`
    border-bottom: none;
    padding-right: 60px;
    padding-top: 0;
    padding-bottom: 0;
    p {
      font-size: 16px;
    }
  `}

  ${media.desktopLarge`
    padding-right: 100px;
  `}

  ${(props: { kiosk: string | null }) => props.kiosk ? (
    media.tabletXLarge`
      width: 40%;
    `) : (
    media.tabletXLarge`
      width: 57%;
    `)
  }
`;

export const StyledBottomRightContainer = styled.div`
  padding-top: 35px;

  ${media.tabletXLarge`
    padding-top: 0px;
  `}

  ${(props: { kiosk: string | null }) => props.kiosk ? (
    media.tabletXLarge`
      width: 60%;
    `) : (
    media.tabletXLarge`
      width: 43%;
    `)
  }
`;

export const StyledBottomContainer = styled.div`
  h4 {
    padding-bottom: 25px;
  }

  h5 {
    padding-bottom: 9px;
  }

  h4, h5 {
    font-size: 22px;
    font-weight: normal;
    -webkit-text-stroke: 0.65px;
  }

  ${media.tabletXLarge`
    display: flex;
    padding-top: 48px;
    h4 {
      padding-bottom: 25px;
    }
    h5 {
      padding-bottom: 9px;
    }
  `}
  ${media.desktopLarge`
    h4, h5 {
      font-size: 24px;
    }
  `}
`;

export const StyledNutrientContainer = styled.div`
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  text-align: right;

  p {
    width: calc(60% / 2);
    line-height: 1.33;
  }

  #nutrient-name {
    font-weight: bold;
    text-align: left;
    width: 40%;
  }

  #nutrient-table-heading {
    text-align: left;
    width: 40%;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 16px 0;

  ${media.desktopLarge`
    font-size: 16px;
  `}
`;

export const StyledItemImage = styled.div`
  width: 100%;
  padding-bottom: 100%;
  background-image: ${(props: { backgroundUrl: string }) => `url(${props.backgroundUrl})`};
  background-color: ${COLORS.LIGHTER_GRAY};
  background-size: contain;
`;

export const StyledNewIcon = styled.img`
  height: 62px;
  width: 62px;
  margin-top: 10px;
  margin-right: 10px;
  float: right;
`;

export const StyledBadgeLabelBlock = styled.div`
  align-items: center;
  display: flex;
  margin-top: 20px;

  h2 {
    font-size: 14px;
  }

  img {
    width: 24px;
    height: 100%;
    margin-right: 13px;
  }
`;
