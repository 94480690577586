/*
 * Colors
 */
const WHITE = '#ffffff';
const BLACK = '#000000';
const BACKGROUNDGRAY = 'rgba(255, 255, 255, 0.4)';
const GRAY = '#404040';
const LIGHT_GRAY = 'rgba(151, 151, 151, 0.19)';
const LIGHTER_GRAY = '#f7f7f7';
const DARK_NAVY = '#303e47';
const LIGHT_NAVY = '#f4f5f6';
const GREEN = '#42f47a';
const ERROR_RED = '#ff2d44';
const RED = '#9f1b32';
const DARKER_RED = '#94192f';

/*
 * Fonts
 */
const PRIMARY_FONT_FAMILY = '"linotype-sabon", "Times", "serif"';
const PRIMARY_LIGHT = '"Avenir"';
const PRIMARY_REGULAR = '"Avenir"';
const PRIMARY_MEDIUM = '"AvenirNext-DemiBold"';
const PRIMARY_BOLD = '"AvenirNext-DemiBold"';
const PRIMARY_TEXT_SIZE = 16;

const SECONDARY_FONT_FAMILY = '"Interstate"';

export const COLORS = {
  PRIMARY: RED,
  SECONDARY: GRAY,
  BLACK,
  DARK_NAVY,
  DARKER_RED,
  RED,
  ERROR_RED,
  GRAY,
  BACKGROUNDGRAY,
  GREEN,
  LIGHT_GRAY,
  LIGHTER_GRAY,
  LIGHT_NAVY,
  WHITE,

  BODY: WHITE,
  NAV: WHITE,
  CONTENT: LIGHT_GRAY,
  PRIMARY_TEXT_COLOR: GRAY,
  PRIMARY_ACTIVE_COLOR: RED,

  PRIMARY_CTA_BACKGROUND_COLOR: RED,
  PRIMARY_CTA_BORDER_COLOR: RED,
  PRIMARY_CTA_TEXT_COLOR: WHITE,
};

export const LAYOUT = {
  MAXWIDTH: 1284,
  GUTTER: 34,
  MOBILE_GUTTER: 12,
  H_SPACING: 50,

  HEADER: {
    M_HEIGHT: 63,
    T_HEIGHT: 73,
    D_HEIGHT: 97,
  },
  NAV: {
    M_HEIGHT: 63,
    T_HEIGHT: 63,
    D_HEIGHT: 66,
  },
  SUBNAV: {
    M_HEIGHT: 57,
    T_HEIGHT: 60,
    D_HEIGHT: 64,
  },
};

export const FONTS = {
  PRIMARY_FONT_FAMILY,
  PRIMARY_TEXT_SIZE,

  PRIMARY: PRIMARY_FONT_FAMILY,
  PRIMARY_LIGHT,
  PRIMARY_REGULAR,
  PRIMARY_MEDIUM,
  PRIMARY_BOLD,

  SECONDARY: SECONDARY_FONT_FAMILY,
};

export const ORDER = {
  MODAL: 8,
  LOADING: 7,
  SKIP_TO_CONTENT: 6,
  NAV: 5,
};
