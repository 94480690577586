export const gaCategories = {
  appliedFilters: 'Applied Filters',
  filterOverlay: 'Filter Overlay',
  search: 'Search',
};

export const gaActions = {
  closeFilterOverlay: 'Close Filter Overlay',
  openFilterOverlay: 'Open Filter Overlay',
  closeSearch: 'Close Search',
  openSearch: 'Open Search',
  performSearch: 'Perform Search',
  applyFilters: 'Apply Filters',
  removeFilter: 'Remove Filter',
  addAllergen: 'Add Allergen',
  removeAllergen: 'Remove Allergen',
  updateIngredients: 'Update Ingredients',
  addDiet: 'Add Diet',
  removeDiet: 'Remove Diet',
};

/*
  Fire a GA event

  @param {String} category
  @param {String} action
  @param {Object} params
                    label
                    value
                    nonInteraction
                    transport
*/
export const fireGaEvent = (category, action, params: { label?: string, value?: string } = {}) => {
  // Fire GTM if enabled
  // window.dataLayer = window.dataLayer || [];
  // window.dataLayer.push({ event: action });

  // Conditionally fire GA
  if (typeof window.gtag !== 'function') {
    return;
  }

  const mergedParams = {
    event_category: category,
    event_label: params.label,
    event_value: params.value,
  };

  window.gtag('event', action, mergedParams);
};
