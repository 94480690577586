import styled from 'styled-components';

// Constants
import { COLORS, FONTS, LAYOUT, ORDER  } from '../../../styles';

export const StyledGenericModal = styled.div`
  background: ${COLORS.BACKGROUNDGRAY};
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ORDER.MODAL};
`;

export const StyledGenericModalContent = styled.div`
  background: ${COLORS.RED};
  max-width: 550px;
  position: relative;
  top: 25%;
  margin: 0 auto 0 auto;

  .message {
    padding:  ${LAYOUT.GUTTER * 2}px  ${LAYOUT.GUTTER}px  ${LAYOUT.GUTTER}px;
    color: ${COLORS.WHITE};
    font-family: ${FONTS.PRIMARY};
    font-size: 22px;
    text-align: center;

    h2, h3, p {
      margin-top: 0;

      span {
        font-weight: normal;
        -webkit-text-stroke: 0.65px;
      }
    }
  }
`;

export const StyledGenericModalFooterForm = styled.form`
  bottom: 0px;
  padding: 0 ${LAYOUT.GUTTER * 3}px ${LAYOUT.GUTTER * 2}px;
  width: 100%;

  a {
    cursor: pointer;
  }

  display: flex;

  p {
    font-family: ${FONTS.PRIMARY};
    font-size: 16px;
    margin: 0 0 10px;
  }
`;

export const StyledPrimaryButton = styled.button`
  background: ${COLORS.WHITE};
  border: none;
  border-radius: 2px;
  color: ${COLORS.RED};
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
  padding: 12px;
  width: 100%;
`;

export const StyledSecondaryButton = styled.button`
  background: transparent;
  border: 1px solid white;
  color: ${COLORS.WHITE};
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
  padding: 12px;
  width: 100%;
`;
