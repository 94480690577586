import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

// Globals
import { COLORS, LAYOUT } from '../styles';
import { media } from '../styles/breakpoints';

export const NavButton = styled(Link)`
  span {
    border-bottom: 1px solid transparent;
  }

  ${(props: {active?: boolean }) => props.active && `
    span {
      color: ${COLORS.RED};
      border-bottom: 1px solid ${COLORS.RED};
    }
  `}

  &:hover {
    color: ${COLORS.RED};
  }
`;

/*
 * Accounts for max-width and gutters across all breakpoints
 */
export const ContainerBlock = styled.div`
  margin: 0 auto;
  max-width: ${LAYOUT.MAXWIDTH}px;
  padding-left: ${LAYOUT.MOBILE_GUTTER}px;
  padding-right: ${LAYOUT.MOBILE_GUTTER}px;
  width: 100%;

  ${media.tabletXLarge`
    padding-left: ${LAYOUT.GUTTER}px;
    padding-right: ${LAYOUT.GUTTER}px;
  `}
`;

export const VisuallyHiddenButton = styled.button`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const deriveMediaQueriesFromHeaderHeight = (property: string) => css`
  ${property.replace('$s1$', LAYOUT.HEADER.M_HEIGHT.toString())}

  ${media.tabletXLarge`
    ${property.replace('$s1$', LAYOUT.HEADER.T_HEIGHT.toString())}
  `}

  ${media.desktopLarge`
    ${property.replace('$s1$', LAYOUT.HEADER.D_HEIGHT.toString())}
  `}
`;

export const StyledNoResults = styled(ContainerBlock)`
  padding-top: 10%;
  text-align: center;
  min-height: calc(100vh - ${LAYOUT.HEADER.M_HEIGHT}px);

  h3, p {
    display: block;
    margin: 0;
  }

  h3 {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 20px;
    -webkit-text-stroke: 0.65px;
  }

  p {
    font-size: 16px;
  }
   ${media.tabletXLarge`
    min-height: calc(100vh - ${LAYOUT.HEADER.T_HEIGHT}px);
  `}

  ${media.desktopLarge`
    min-height: calc(100vh - ${LAYOUT.HEADER.D_HEIGHT}px);
  `}
`;
