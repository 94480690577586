import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

// Services
import { filterProducts } from '../../services/search.service';
// Utils
import { deriveDefaultMenuRoute, menuIndexByProductId } from '../../utils/menu';
// Menu Actions
import menuActions from '../menu/actions';
import searchActions from '../search/actions';
import actions from './actions';

export function* filterMenuSaga(action: AnyAction) {
  try {

    // If filtering from a search results page, also run a keywords search
    if (action.redoSearch) {
      const searchValue = yield select(state => state.search.value);

      yield put(searchActions.searchMenu(Object.assign({}, action.values, {
        keywords: [searchValue],
      })));
    }

    // Run the menu filter as well
    const response = yield call(filterProducts, action.values);

    // Set applied filters
    yield put(actions.setFilterResults(action.values));

    // Set the menu response
    const menu = response.data.menu;
    const allergens = response.data.allergens;
    const dietaryFilters = response.data.dietary_filters;
    const ingredients = response.data.ingredients;
    const menuIndex = yield call(menuIndexByProductId, menu);
    yield put(menuActions.fetchMenuSuccess(menu, allergens, dietaryFilters, ingredients, menuIndex));

    //
    if (action.pushToMenu) {
      yield put(push(deriveDefaultMenuRoute(menu)));
    }
  } catch (error) {
    // Error Notification
    console.log('error in filterMenu saga', error);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.APPLY_FILTERS, filterMenuSaga);
}
