export const pathParams = {
  occasionId: 'occasionId',
  categoryId: 'categoryId',
  productId: 'productId',
  searchTerm: 'searchTerm',
};

export const routes = {
  splash: {
    base: '/app',
  },
  menu: {
    base: '/',
    occasion: {
      base: `/o/:${pathParams.occasionId}`,
      category: `/o/:${pathParams.occasionId}/c/:${pathParams.categoryId}`,
    },
  },
  product: {
    base: `/p/:${pathParams.productId}`,
    verbose: `/o/:${pathParams.occasionId}/c/:${pathParams.categoryId}/p/:${pathParams.productId}`,
  },
  search: {
    base: `/search/:${pathParams.searchTerm}`,
  },
};
