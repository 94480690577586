import styled from 'styled-components';

// Styles
import { COLORS, LAYOUT } from '../../styles';
// Breakpoints
import { media } from '../../styles/breakpoints';
import { ContainerBlock, VisuallyHiddenButton } from '../../styles/mixins';

export const StyledSearch = styled.div`
  background: ${COLORS.WHITE};
  border-bottom: 1px solid ${COLORS.WHITE};
  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  height: ${LAYOUT.HEADER.M_HEIGHT}px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  ${media.tabletXLarge`
    height: ${LAYOUT.HEADER.T_HEIGHT}px;
  `}

  ${media.desktopLarge`
    height: ${LAYOUT.HEADER.D_HEIGHT}px;
  `}
`;

export const StyledSearchContent = styled(ContainerBlock)`
  display: flex;
  flex-direction: row-reverse;

  ${media.tabletXLarge`
    flex-direction: row;
  `}

  input {
    border: none;
    flex: 1;
  }
  button {
  }
`;

export const StyledCloseButton = styled.button`
  background: none;
  border: none;
  color: ${COLORS.RED};
  cursor: pointer;
  font-size: 32px;
  margin: 0 ${LAYOUT.MOBILE_GUTTER * -1}px 0 0;
  padding: 0 ${LAYOUT.MOBILE_GUTTER}px;
  width: 70px;

  ${media.tabletXLarge`
    margin: 0 0 0 ${LAYOUT.GUTTER * -1}px;
    padding: 0 ${LAYOUT.GUTTER / 2}px 0 ${LAYOUT.GUTTER}px;
  `}
`;

export const StyledSearchButton = styled(VisuallyHiddenButton)``;

export const StyledSearchInput = styled.input`
  color: ${COLORS.GRAY};
  font-size: 20px;

  ${media.tabletXLarge`
    font-size: 24px;
    padding-left: ${LAYOUT.GUTTER / 2}px;
  `}
`;
