const filterActions = {
  TOGGLE_ALLERGEN: '/pret-allergens/filters/TOGGLE_ALLERGEN',
  toggleAllergen: (safeName: string) => ({
    type: filterActions.TOGGLE_ALLERGEN,
    safeName,
  }),

  TOGGLE_DIET: '/pret-allergens/filters/TOGGLE_DIET',
  toggleDiet: (safeName: string) => ({
    type: filterActions.TOGGLE_DIET,
    safeName,
  }),

  APPLY_FILTERS: '/pret-allergens/filters/APPLY_FILTERS',
  applyFilters: (values: {}, redoSearch: boolean, pushToMenu: boolean) => ({
    type: filterActions.APPLY_FILTERS,
    values,
    redoSearch,
    pushToMenu,
  }),

  SET_FILTER_RESULTS: '/pret-allergens/filters/SET_FILTER_RESULTS',
  setFilterResults: (values: {}) => ({
    type: filterActions.SET_FILTER_RESULTS,
    values,
  }),
};

export default filterActions;
