const menuActions = {
  FETCH_FAIL: '/pret-allergens/menu/FETCH_FAIL',
  FETCH_MENU: '/pret-allergens/menu/FETCH_MENU',
  fetchMenu: (params: object, supressRedirect?: boolean) => ({
    type: menuActions.FETCH_MENU,
    params,
    supressRedirect,
  }),
  FETCH_MENU_SUCCESS: '/pret-allergens/menu/FETCH_MENU_SUCCESS',
  fetchMenuSuccess: (menu, allergens, dietaryFilters, ingredients, menuIndex) => ({
    type: menuActions.FETCH_MENU_SUCCESS,
    menu,
    allergens,
    dietaryFilters,
    ingredients,
    menuIndex,
  }),
};

export default menuActions;
