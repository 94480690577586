import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Styles
import { StyledLogo } from './styles';

// Utils
import { deriveDefaultMenuRoute } from '../../utils/menu';

interface StoreProps {
  menu: any;
}

const HeaderLogo = ({ menu }: StoreProps) => (
  <Link to={deriveDefaultMenuRoute(menu)} data-cy="logo">
    <StyledLogo
      src="/img/logo.png"
      alt="Pret A Manger"
    />
  </Link>
);

const mapStateToProps = state => ({
  menu: state.menu.data,
});

export default connect(mapStateToProps)(HeaderLogo);
