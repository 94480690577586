import FocusTrap from 'focus-trap-react';
import React from 'react';

// Styles
import {
  StyledGenericModal,
  StyledGenericModalContent,
  StyledGenericModalFooterForm,
  StyledPrimaryButton,
  StyledSecondaryButton,
} from './styles';

interface Props {
  modalOpen: boolean;
  toggleModal: () => void;
  cancelAction: () => void;
  confirmMessage?: string;
  cancelMessage?: string;
}

class GenericModal extends React.Component<Props> {

  render() {
    const { cancelMessage, confirmMessage, modalOpen, toggleModal } = this.props;

    if (!modalOpen) {
      return <span />;
    }

    return (
      <FocusTrap focusTrapOptions={{
        initialFocus: '#GenericModalSubmit',
      }}>
        <StyledGenericModal data-cy="modal">
          <StyledGenericModalContent>
              <div className="message">
                {this.props.children}
              </div>
              <StyledGenericModalFooterForm>
                <StyledPrimaryButton
                  id="GenericModalSubmit"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    toggleModal();
                  }}
                  data-cy="confirm"
                >
                  {confirmMessage ? confirmMessage : 'Confirm'}
                </StyledPrimaryButton>
                <StyledSecondaryButton type="button" onClick={() => this.props.cancelAction()} data-cy="cancel">
                  {cancelMessage ? cancelMessage : 'Cancel'}
                </StyledSecondaryButton>
              </StyledGenericModalFooterForm>
          </StyledGenericModalContent>
        </StyledGenericModal>
    </FocusTrap>
    );
  }
}

export default GenericModal;
