const IMAGE_RESIZER = 'https://resizer.product.pret.co.uk';
/**
 * Take a url and feed it to the image resizer
 *
 * @param {String} str
 * @returns {String|Array}
 */
export function resizeImage(url, height = 9999, width = 9999, quality = 85, crop = true) {
  // Ensure url exists
  if (!url) {
    return null;
  }

  // Don't try and image resize SVGs
  if (url.split('.')[url.split('.').length - 1] === 'svg') {
    return url;
  }

  // Don't resize the image if there is no image resizer
  if (!IMAGE_RESIZER) {
    return url;
  }

  return `${IMAGE_RESIZER}/resize?source=${url}&height=${height}&width=${width}&max_quality=${quality}&crop=${crop}`;
}
