import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

// Actions
import menuActions from '../../redux/menu/actions';

// Utils
import { deriveDefaultMenuRoute } from '../../utils/menu';

type StoreProps = {
  menuData: [];
  menuIndex: object,
  menuLoading: boolean,
  fetchMenu: (arg: {}, supressRedirect?: boolean) => void;
};

type PassProps = {
  push: (path: string) => void;
  match: {
    params: {
      [key: string]: string;
    };
  };
  supressRedirect?: boolean;
};

export default function menuHandler(WrappedComponent: any) {
  class PageComponent extends React.Component<StoreProps & PassProps> {
    componentDidMount() {
      const { fetchMenu, menuData, match, supressRedirect } = this.props;

      // If no menu, fetch it whilst passing all available path params
      if (!menuData.length) {
        fetchMenu(match.params, supressRedirect);
      } else {
        this.reconcileAmbiguousUrl();
      }
    }

    componentDidUpdate() {
      this.reconcileAmbiguousUrl();
    }

    reconcileAmbiguousUrl = () => {
      const { menuData, match: { params: { occasionId, categoryId } }, supressRedirect } = this.props;

      // If we have the menu, but we don't have any parameters
      if (!supressRedirect && menuData.length && !occasionId && !categoryId) {
        this.props.push(deriveDefaultMenuRoute(menuData));
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = (state: any) => ({
    menuData: state.menu.data,
    menuIndex: state.menu.menuIndex,
    menuLoading: state.menu.loading,
  });

  const mapDispatchToProps = (dispatch: any) => ({
    fetchMenu: (arg: {}, supressRedirect?: boolean) => dispatch(menuActions.fetchMenu(arg, supressRedirect)),
    push: (path: string) => dispatch(push(path)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(PageComponent);
}
