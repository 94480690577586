import * as React from 'react';
import { connect } from 'react-redux';
import {
  StyledContainer,
  StyledContent,
  StyledOccasionLink,
} from './styles';

// Routes
import { pathParams } from '../../constants/routes';

// Utils
import { determineFirstCategoryId } from '../../utils/menu';

type StoreProps = {
  menu: any;
};

type PassProps = {
  // TODO - extract into type
  matchParams: {
    [key: string]: string;
  };
};

class TopNav extends React.Component<StoreProps & PassProps> {

  render() {
    const occasionId = this.props.matchParams[pathParams.occasionId];
    const { menu } = this.props;

    /* On click of occasion name in nav (ie: breakfast, lunch, all-day), subnav will always display first menu category */
    return (
      <StyledContainer>
        <StyledContent data-cy="occasions">
          {menu && menu.length > 0 && menu
            .map(occasion => (
              <StyledOccasionLink
                to={`/o/${occasion.safe_name}/c/${determineFirstCategoryId(occasion.safe_name, menu)}`}
                key={`top-nav-${occasion.safe_name}`}
                active={occasion.safe_name === occasionId}
                data-cy={occasion.safe_name === occasionId ? 'active' : 'inactive'}
              >
                <span data-cy={occasion.safe_name}>{occasion.name}</span>
              </StyledOccasionLink>
            ))
          }
        </StyledContent>
      </StyledContainer>
    );
  }
}

const mapStateToProps = state => ({
  menu: state.menu.data,
});

export default connect(mapStateToProps)(TopNav);
