import find from 'lodash/find';
import React from 'react';
import { connect } from 'react-redux';

// Actions
import filterActions from '../../redux/filters/actions';
import globalActions from '../../redux/global/actions';
// Types
import { InterfaceAllergen, InterfaceDiet } from '../../redux/menu/reducer';
// Globals
import { isMobile } from '../../styles/breakpoints';
// Utils
import { fireGaEvent, gaActions, gaCategories } from '../../utils/analytics';
import {
    StyledFilterStatus, StyledFilterStatusContainer, StyledFilterTags, StyledMoreFilters, StyledTag,
} from './styles';

type StoreProps = {
  allergens: InterfaceAllergen[];
  diets: InterfaceDiet[];
  ingredients: string[];
};

type MenuStoreProps = {
  menuAllergens: object[];
  menuDietaryFilters: object[];
  menuIngredients: object[];
};

type DispatchProps = {
  applyFilters: (values: StoreProps, redoSearch: boolean) => void;
  toggleFilterOverlay: (visible: boolean) => void;
};

type PassProps = {
  redoSearch?: boolean;
};

const removeFilter = (safeName: string, key: string, filters) => Object.assign({}, filters, {
  [key]: filters[key].filter((filter: string) => filter !== safeName),
});

const deriveNameFromSafeName = (safeName: string, filters: any[]) => {
  const filterObject = find(filters, { safe_name: safeName });

  if (filterObject) {
    return filterObject.name;
  }

  return safeName;
};

const determineMaxFiltersDisplay = () => {
  // maxContainerWidth is derived by either the max container width or the current container width if innerWidth is less than the max
  const maxContainerWidth = window.innerWidth > 1216 ? 1216 : window.innerWidth;

  // maxTagWidth is 20% of the maxContainerWidth, but maxes out at 160px
  // on mobile, maxTagWidth is 33.33% of maxContainerWidth
  const maxTagsPerRow = isMobile() ? 3 : 5;
  const maxTagWidth = (maxContainerWidth / maxTagsPerRow < 155) ? maxContainerWidth / maxTagsPerRow : 155;

  // The filter text will occupy 100px of width, except on mobile
  const filterTextWidth = isMobile() ? 0 : 110;

  // Calculate the width the the tags might possibly occupy
  const maxTagDisplayArea = maxContainerWidth - filterTextWidth;

  // See how many tags can fit in that space and round that value down
  const maxCount = Math.floor(maxTagDisplayArea / maxTagWidth);

  // Subtract 1 to account for the space the '+ X more' text will need to occupy
  return maxCount - 1;
};

const FilterStatus = ({
  allergens,
  applyFilters,
  diets,
  ingredients,
  menuAllergens,
  menuDietaryFilters,
  menuIngredients,
  redoSearch,
  toggleFilterOverlay,
}: StoreProps & DispatchProps & MenuStoreProps & PassProps) => {

  const menuFilters = {
    allergens: menuAllergens,
    diets: menuDietaryFilters,
    ingredients: menuIngredients,
  };

  const appliedFilters = {
    allergens,
    diets,
    ingredients,
  };

  const totalFilters = allergens.length + diets.length + ingredients.length;
  const maxCount = determineMaxFiltersDisplay();

  return (
    <StyledFilterStatusContainer data-cy="applied-filters">
      {(allergens.length || diets.length || ingredients.length) ? (
        <StyledFilterStatus>
          <p>Filtering</p>

          <StyledFilterTags>
            {/* Render all filters */}
            {(() => {
              let currentCount = 0;

              return Object.keys(appliedFilters).map((key: string) => (
                appliedFilters[key].map((filter: string, index: string) => {
                  ++currentCount;

                  if (currentCount > maxCount) {
                    return null;
                  }

                  return (
                    <StyledTag
                      key={index}
                      isSelected={true}
                      onClick={() => {
                        applyFilters(removeFilter(filter, key, appliedFilters), !!redoSearch);
                        fireGaEvent(gaCategories.appliedFilters, gaActions.removeFilter, { label: filter });
                      }}
                      testId="active-filter"
                    >
                      {deriveNameFromSafeName(filter, menuFilters[key])}
                    </StyledTag>
                  );
                })
              ));
            })()}

            {/* Conditionally show additional filters button */}
            {(totalFilters > maxCount) && (
              <StyledMoreFilters>
                <button
                  aria-label={`Click to show ${totalFilters - maxCount} additional filters`}
                  onClick={() => {
                    toggleFilterOverlay(true);
                    fireGaEvent(gaCategories.filterOverlay, gaActions.openFilterOverlay, { label: 'From Applied Filters' });
                  }}
                >
                  + {totalFilters - maxCount} more
                </button>
              </StyledMoreFilters>
            )}
          </StyledFilterTags>
        </StyledFilterStatus>
       ) : ''}
    </StyledFilterStatusContainer>
  );
};

const mapStateToProps = state => ({
  // Applied filters
  allergens: state.filters.allergens,
  diets: state.filters.diets,
  ingredients: state.filters.ingredients,

  // Filter options
  menuAllergens: state.menu.allergens,
  menuDietaryFilters: state.menu.dietaryFilters,
  menuIngredients: state.menu.ingredients,
});

const mapDispatchToProps = dispatch => ({
  applyFilters: (values: StoreProps, redoSearch: boolean) => dispatch(filterActions.applyFilters(values, redoSearch, false)),
  toggleFilterOverlay: (visible: boolean) => dispatch(globalActions.toggleFilterOverlay(visible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterStatus);
