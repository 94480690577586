import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

declare global {
  interface Window {
    gtag: any;
    Cypress: any;
    store: any;
  }
}

class GoogleAnalytics extends React.Component<{ location: any } & RouteComponentProps> {
  componentWillUpdate({ location, history }) {
    const gtag = window.gtag;

    if (location.pathname === this.props.location.pathname) {
      return;
    }

    if (history.action === 'PUSH' && typeof(gtag) === 'function') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
      });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
