import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

// Routes
import { pathParams } from '../../constants/routes';
// Services
import { fetchProducts } from '../../services/products.service';
// Utils
import {
    determineFirstCategoryId, interpolateMOCPath, menuIndexByProductId,
} from '../../utils/menu';
// Actions
import actions from './actions';

// TODO - type these params!
export function* fetchMenu({ params, supressRedirect }: AnyAction) {
  try {
    const response = yield call(fetchProducts);
    const menu = response.data.menu;
    const allergens = response.data.allergens;
    const dietaryFilters = response.data.dietary_filters;
    const ingredients = response.data.ingredients;
    const menuIndex = yield call(menuIndexByProductId, menu);
    let firstOccasionName = params[pathParams.occasionId];
    let firstCategoryId = params[pathParams.categoryId];

    // If we're loading the menu from a non-menu page
    if (!firstOccasionName || !firstCategoryId) {
      firstOccasionName = menu.map((item: any) => item.safe_name)[0];
      firstCategoryId = determineFirstCategoryId(firstOccasionName, menu);

      // Redirect to default menu view unless the menu is simply loading in the background
      if (!supressRedirect) {
        yield put(push(interpolateMOCPath(firstOccasionName, firstCategoryId)));
      }
    }

    yield put(actions.fetchMenuSuccess(menu, allergens, dietaryFilters, ingredients, menuIndex));
  } catch (error) {
    // Error Notification
    yield put({ type: actions.FETCH_FAIL });
    console.log('error in fetchMenu saga', error);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_MENU, fetchMenu);
}
