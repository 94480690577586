import { FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import AsyncSelect from 'react-select/lib/Async';
// Actions
import filterActions from '../../redux/filters/actions';
// Types
import { InterfaceAllergen, InterfaceDiet } from '../../redux/menu/reducer';
// Styles
import { COLORS } from '../../styles';
// Utils
import { fireGaEvent, gaActions, gaCategories } from '../../utils/analytics';
import {
    StyledApplyButton, StyledButtonsContainer, StyledClearFiltersButton, StyledDisclaimerBody, StyledDisclaimerHeader, StyledDisclaimerHolder,
    StyledTag, StyledTagContainer,
} from './styles';

interface InterfaceFormAllergens extends InterfaceAllergen {
  isSelected: boolean;
}

interface InterfaceFormDiets extends InterfaceDiet {
  isSelected: boolean;
}

interface InterfaceSelectIngredients {
  value: string;
  label: string;
}

interface FormSubmitValues {
  allergens: string[];
  diets: string[];
  ingredients: string[];
  flat?: boolean;
}

type PassProps = {
  redoSearch?: boolean;
  pushToMenu?: boolean;
};

type StoreProps = {
  applyFilters: (values: FormSubmitValues, redoSearch: boolean, pushToMenu: boolean) => void;
  filtersLoading: boolean;

  // Applied filters
  allergenFilters: string[];
  ingredientsFilters: string[];
  dietFilters: string[];

  // Options
  allergens: InterfaceAllergen[];
  ingredients: string[];
  dietaryFilters: InterfaceDiet[];
};

type Option = {
  value: string;
  label: string;
};

const applyIsSelected = (arr, filters, settings?) => arr.map((item: InterfaceAllergen | InterfaceDiet) => (
  Object.assign({}, item, {
    isSelected: settings && settings.resetApplied ? false : filters.indexOf(item.safe_name) > -1,
  })
));

const assembleSubmitKeyArray = arr => arr.filter((item: InterfaceFormDiets | InterfaceFormAllergens) => item.isSelected).map((item: InterfaceFormDiets | InterfaceFormAllergens) => item.safe_name);

// React-select expects options to
const formatIngredients = (arr: string[]) => arr.map((item: string) => ({ value: item, label: item }));

const FilterForm = ({
  allergens,
  allergenFilters,
  applyFilters,
  dietaryFilters,
  dietFilters,
  filtersLoading,
  ingredients,
  ingredientsFilters,
  pushToMenu,
  redoSearch,
}: StoreProps & PassProps) => {

  const [selectInputVal, setSelectInputVal] = useState('');
  const [reset, setReset] = useState(false);
  const [formattedIngredients] = useState(formatIngredients(ingredients));

  const filteredIngredients = (input: string, cb: (arg: Option[]) => void) => {
    // filter for exact match with input value
    const exactMatch = ingredients.filter((item: string) => selectInputVal.toLowerCase() === item.toLowerCase());
    // filter for starts with input value
    const startsWith = ingredients.filter((item: string) => selectInputVal.toLowerCase() !== item.toLowerCase() && item.toLowerCase().startsWith(selectInputVal.toLowerCase()));
    // filter for includes input value
    const includes = ingredients.filter((item: string) => selectInputVal.toLowerCase() !== item.toLowerCase() && item.toLowerCase().includes(selectInputVal.toLowerCase()) && !item.toLowerCase().startsWith(selectInputVal.toLowerCase()));
    // combine results of 3 arrs
    const results = exactMatch.concat(startsWith).concat(includes);
    // format results for React-select
    const formattedResults = formatIngredients(results);
    return cb(formattedResults);
  };

  const handleInputChange = value => {
    setSelectInputVal(value);
  };

  let selectRef;
  const handleRef = value => {
    selectRef = value;
  };

  // In other words, the menu hasn't loaded yet
  if (!allergens.length || !dietaryFilters.length) {
    return <span />;
  }

  const kiosk = localStorage.getItem('kiosk');

  return (
    <>
      <Formik
        initialValues={{
          allergens: applyIsSelected(allergens, allergenFilters),
          diets: applyIsSelected(dietaryFilters, dietFilters),
          ingredients: formatIngredients(ingredientsFilters),
        }}
        onSubmit={values => {
          // Reformat formik values for API
          let filters = {
            allergens: assembleSubmitKeyArray(values.allergens),
            diets: assembleSubmitKeyArray(values.diets),
            ingredients: values.ingredients.map((obj: InterfaceSelectIngredients) => obj.value),
          };
          if (reset) {
            filters = {
              allergens: [],
              diets: [],
              ingredients: [],
            };
          }
          applyFilters(filters, !!redoSearch, !!pushToMenu);
          fireGaEvent(gaCategories.filterOverlay, gaActions.applyFilters);
        }}
        render={({ setFieldValue, values, resetForm }) => (
          <Form data-cy="filters-overlay">
            {/* Allergens Tags */}
            <h1>Show me items made without...</h1>
            <StyledTagContainer kiosk={kiosk}>
              <FieldArray
                name="allergens"
                render={({ replace }) => (
                  <>
                    {values.allergens.map((allergen: InterfaceFormAllergens, index) => (
                      <StyledTag
                        kiosk={kiosk}
                        key={index}
                        isSelected={allergen.isSelected}
                        onClick={() => {
                          setReset(false);
                          replace(index, Object.assign({}, allergen, {
                            isSelected: !allergen.isSelected,
                          }));
                          fireGaEvent(gaCategories.filterOverlay, !allergen.isSelected ? gaActions.addAllergen : gaActions.removeAllergen, { label: allergen.safe_name });
                        }}
                      >
                        {allergen.name}
                      </StyledTag>
                    ))}
                  </>
                )}
              />
            </StyledTagContainer>

            {/* Custom Ingredients */}
            <StyledTagContainer kiosk={kiosk} data-cy="ingredients">
              <h2>Filter out additional ingredients below:</h2>
              <StyledDisclaimerHolder>
                <StyledDisclaimerHeader>Please take care:</StyledDisclaimerHeader>
                <StyledDisclaimerBody>In case of typos, misspellings or local variations, always refer to individual product pages for full ingredient information, including listed food allergens.</StyledDisclaimerBody>
              </StyledDisclaimerHolder>
              <AsyncSelect
                placeholder="Search for ingredient here"
                defaultOptions={formattedIngredients}
                loadOptions={filteredIngredients}
                isMulti={true}
                ref={handleRef}
                onChange={(arr: InterfaceSelectIngredients, params: {
                  action: string;
                  option?: { value: string; label: string; };
                  removedValue?: { value: string; label: string; }
                }) => {

                  if (params.action && params.action === 'clear') {
                    if (selectRef) {
                      selectRef.focus();
                    }
                  }

                  setReset(false);
                  setFieldValue('ingredients', arr);

                  // GA wrangling
                  let label;

                  // If an add event
                  if (params.option) {
                    label = { label: params.option.label };
                  }

                  // If a remove event
                  if (params.removedValue) {
                    label = { label: params.removedValue.label };
                  }

                  fireGaEvent(gaCategories.filterOverlay, params.action, label);
                }}
                onInputChange={handleInputChange}
                value={values.ingredients}
                styles={{
                  container: styles => ({
                    ...styles,
                    flex: 1,
                  }),
                  multiValue: styles => ({
                    ...styles,
                    backgroundColor: COLORS.RED,
                    borderRadius: 2,
                    color: COLORS.WHITE,
                    padding: 8,
                  }),
                  multiValueLabel: styles => ({
                    ...styles,
                    color: COLORS.WHITE,
                  }),
                  control: styles => ({
                    ...styles,
                    boxShadow: 'none',
                  })}
                }
                theme={theme => {
                  return ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: '#efefef', // hover
                      primary: COLORS.RED,
                      neutral20: COLORS.RED,
                      neutral30: COLORS.RED,
                      neutral40: COLORS.RED,
                      neutral50: COLORS.RED,
                      neutral60: COLORS.RED,
                    },
                    spacing: {
                      ...theme.spacing,
                      controlHeight: 48,
                    },
                  }); }
                }
              />
            </StyledTagContainer>

            {/* Dietary Preferences */}
            <h1>My dietary preference is...</h1>
            <StyledTagContainer kiosk={kiosk}>
              <FieldArray
                name="diets"
                render={({ replace }) => (
                  <>
                    {values.diets.map((diet: InterfaceFormDiets, index) => (
                      <StyledTag
                        kiosk={kiosk}
                        key={index}
                        isSelected={diet.isSelected}
                        onClick={() => {
                          replace(index, Object.assign({}, diet, {
                            isSelected: !diet.isSelected,
                          }));
                          fireGaEvent(gaCategories.filterOverlay, !diet.isSelected ? gaActions.addDiet : gaActions.removeDiet, { label: diet.safe_name });
                        }}
                      >
                        {diet.name}
                      </StyledTag>
                    ))}
                  </>
                )}
              />
            </StyledTagContainer>
            <StyledButtonsContainer
              kiosk={kiosk}
            >
              <StyledApplyButton type="submit" data-cy="apply">
                {filtersLoading ?
                <>
                  <img role="presentation" src="/img/icons/filter_loader.gif" />
                  <div id="label">Applying...</div>
                </>
                : <div id="label">Apply Filters</div>
                }
              </StyledApplyButton>
              <StyledClearFiltersButton
                type="button"
                onClick={() => {
                  resetForm({
                    allergens: applyIsSelected(allergens, allergenFilters, { resetApplied: true }),
                    diets: applyIsSelected(dietaryFilters, dietFilters, { resetApplied: true }),
                    ingredients: [],
                  });
                  setReset(true);
                }}
                data-cy="reset"
              >
                Reset
              </StyledClearFiltersButton>
            </StyledButtonsContainer>
          </Form>
        )}
      />
    </>
  );
};

const mapStateToProps = state => ({
  // Applied Filters
  allergenFilters: state.filters.allergens,
  ingredientsFilters: state.filters.ingredients,
  dietFilters: state.filters.diets,
  filtersLoading: state.filters.loading,

  // Filter options
  allergens: state.menu.allergens,
  ingredients: state.menu.ingredients,
  dietaryFilters: state.menu.dietaryFilters,
});

const mapDispatchToProps = dispatch => ({
  applyFilters: (values: FormSubmitValues, redoSearch: boolean, pushToMenu: boolean) => dispatch(filterActions.applyFilters(values, redoSearch, pushToMenu)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
