import React from 'react';
import { connect } from 'react-redux';

// Actions
import globalActions from '../../redux/global/actions';

// Styles
import { StyledFilterButton } from './styles';

// Utils
import { fireGaEvent, gaActions, gaCategories } from '../../utils/analytics';

interface FilterProps {
  toggleFilterOverlay: (visible: boolean) => void;
  filterOverlayVisible: boolean;
}

const FilterToggle = ({ filterOverlayVisible, toggleFilterOverlay }: FilterProps) => {
  return (
    <StyledFilterButton
      onClick={() => {
        toggleFilterOverlay(!filterOverlayVisible);
        fireGaEvent(gaCategories.filterOverlay, gaActions.openFilterOverlay, { label: 'From Nav' });
      }}
      data-cy="filter"
    >
      <img
        role="presentation"
        src="/img/icons/filters.svg"
      />
      <span>Filter Items Without</span>
    </StyledFilterButton>
  );
};

const mapStateToProps = state => ({
  filterOverlayVisible: state.global.filterOverlayVisible,
});

const mapDispatchToProps = dispatch => ({
  toggleFilterOverlay: (visible: boolean) => dispatch(globalActions.toggleFilterOverlay(visible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterToggle);
