import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Styles
import { COLORS, FONTS } from '../../styles';
// Breakpoints
import { media } from '../../styles/breakpoints';

export const StyledLogo = styled.img`
  position: absolute;
  right: 24px;
  top: 24px;
  width: 78px;

  ${media.tabletXLarge`
    width: 87.2px;
  `}

  ${media.desktopLarge`
    top: 30px;
    right: 30px;
    width: 128px;
  `}
`;

export const StyledWelcomeContainer = styled(Link)`
  display: block;
  left: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;

  > div {
    display: flex;
    margin: 0 auto;
    min-height: 100vh;
    max-width: 1440px;
    width: 100%;
  }
`;

export const StyledImageContainer = styled.div`
  flex-basis: 97px;
  position: relative;

  ${media.tablet`
    flex-basis: 198px;
  `}

  ${media.desktopLarge`
    flex-basis: 32.78%;
  `}

  img {
    min-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const StyledTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 28px;
  width: 100%;

  > div {
    max-width: 395px;
  }

  h1, h2, p {
    color: ${COLORS.GRAY};
    font-family: ${FONTS.PRIMARY};
    font-weight: normal;
  }

  h1 {
    font-size: 30px;
    margin: 0 0 20px;
  }

  h2 {
    font-size: 24px;
    margin: 0 0 20px;
    position: relative;

    span {
      background: white;
      padding: 0 20px;
      position: relative;
      z-index: 1;
    }

    &:after {
      background: #979797;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 100%;
    }
  }

  p {
    color: ${COLORS.RED};
    font-size: 22px;
    margin: 0;
  }

  ${media.tablet`
    h1 {
      font-size: 44px;
      margin: 0 0 30px;
    }

    h2 {
      margin: 0 0 30px;
    }
  `}

  ${media.desktopLarge`
    h1 {
      font-size: 64px;
    }

    h2 {
      font-size: 34px;
    }

    p {
      font-size: 24px;
    }
  `}
`;
