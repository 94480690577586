import React from 'react';

// Utils
import { pathParams } from '../../constants/routes';
import { resizeImage } from '../../utils/imageHelper';
import { determineShowIcon } from '../../utils/menu';
// Components
import { BackToMenu } from '../BackToMenu';
// Styles
import { StyledMenuLoading } from '../MenuGrid/styles';
// Styles
import {
    StyledBadgeLabelBlock, StyledBottomContainer, StyledBottomLeftContainer,
    StyledBottomRightContainer, StyledContainer, StyledItemImage, StyledNewIcon,
    StyledNutrientContainer, StyledTopContainer, StyledTopLeftContainer,
    StyledTopRightContainer,
} from './styles';

type PassProps = {
  goBack: () => void;
  matchParams: {
    [key: string]: string;
  };
  menuIndex: object;
  menuLoading: boolean;
};

const ProductDetail = ({ goBack, matchParams, menuIndex, menuLoading }: PassProps) => {
  const kiosk = localStorage.getItem('kiosk');
  const productId = matchParams[pathParams.productId];
  const product: any = menuIndex[productId];
  const determineMetric = nutrient => {
    let result = '(g)';
    if (nutrient.name === 'Sodium') {
      result = '(mg)';
    } else if (nutrient.name === 'Energy (KCal)') {
      result = '';
    } else if (nutrient.name === 'Energy (KJ)') {
      result = '';
    }
    return result;
  };

  if (!product) {
    return (<StyledMenuLoading
      loading={menuLoading}
    >
      <img
        role="presentation"
        alt="Loading Spinner"
        src="/img/icons/star_loader.gif"
        data-cy="loading"
      />
    </StyledMenuLoading>);
  }

  return (
    <StyledContainer data-cy="product-details">
      <BackToMenu
        onClick={goBack}
      />
      <StyledTopContainer>
        <StyledTopRightContainer kiosk={kiosk}>
          <StyledItemImage backgroundUrl={resizeImage(product.image_url, 600, 600)} data-cy="image">
            {(() => {
              return product.is_new && (<StyledNewIcon
                  alt="New"
                  title="New"
                  src={`/img/icons/new.png`}
                  data-cy="new-icon"/>);
            })()}
          </StyledItemImage>
        </StyledTopRightContainer>
        <StyledTopLeftContainer kiosk={kiosk}>
          <h1 data-cy="name">{product.marketing_name}</h1>
          {(() => {
            const icon = determineShowIcon(product);

            return icon && (
              <StyledBadgeLabelBlock data-cy="badge">
                <img
                  alt={icon}
                  src={`/img/icons/${icon}.svg`}
                  data-cy={icon}
                />
                <h2>{product.badge_description}</h2>
              </StyledBadgeLabelBlock>
            );
          })()}
          <p data-cy="description">{product.marketing_description}</p>
          <p id="allergens" data-cy="allergens">{product.allergens_description}</p>
        </StyledTopLeftContainer>
      </StyledTopContainer>
      <StyledBottomContainer>
        <StyledBottomLeftContainer data-cy="ingredients" kiosk={kiosk}>
          <h4>Ingredients</h4>
          <p dangerouslySetInnerHTML={{ __html: product.ingredients_description }}/>
          <br/><br/>
          <p><em>Adults need around 2000 kcal a day</em></p>
        </StyledBottomLeftContainer>
        <StyledBottomRightContainer data-cy="nutrients" kiosk={kiosk}>
          <h5 data-cy="title">Nutritional Stats, Facts, and Figures</h5>
          <StyledNutrientContainer>
            <p id="nutrient-table-heading" data-cy="table">Table of Nutritional Information</p>
            <p data-cy="100-g-title">Per 100 g</p>
            <p data-cy="avg-title">Per {product.average_weight} g<br /> serving</p>
          </StyledNutrientContainer>
        {product.nutrients.map((nutrient, index) =>
          <StyledNutrientContainer key={index} data-cy="nutrient-row">
            <p id="nutrient-name" data-cy="nutrient-name">{nutrient.name} {determineMetric(nutrient)}</p>
            <p id="nutrient-100-g-value" data-cy="100-g-value">{nutrient.per_100_g || '--'}</p>
            <p id="nutrient-value" data-cy="avg-value">{nutrient.per_serving || '--'}</p>
          </StyledNutrientContainer>,
        )}
        </StyledBottomRightContainer>
      </StyledBottomContainer>
    </StyledContainer>
  );
};

export default ProductDetail;
