import { darken } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Globals
import { COLORS, FONTS, LAYOUT } from '../../styles';
import { media } from '../../styles/breakpoints';
import { ContainerBlock } from '../../styles/mixins';

export const StyledItemImage = styled.div`
  display: block;
  height: auto;
  margin-bottom: 10px;
  width: 100%;
  padding-bottom: 100%;
  background-image: ${(props: { backgroundUrl: string }) => `url(${props.backgroundUrl})`};
  background-color: ${COLORS.LIGHTER_GRAY};
  background-size: contain;
`;

export const StyledDietaryIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-bottom: 10px;
`;

export const StyledNewIcon = styled.img`
  height: 32px;
  width: 32px;
  margin-top: 5px;
  margin-right: 5px;
  float: right;

  ${media.desktop`
    height: 42px;
    width: 42px;
  `}
`;

export const StyledMenuGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 10px;
`;

export const StyledMenuGridContainer = styled(ContainerBlock)`
  padding: 0 !important;
`;

export const StyledItemCardContainer = styled.div`
  cursor: pointer;
  margin-bottom: 30px;
  text-align: left;
  width: 50%;
  padding: 0 ${LAYOUT.MOBILE_GUTTER}px;

  &:hover {
    h1 {
      color: ${darken(0.075, COLORS.RED)};
    }
    h2 {
      color: ${darken(0.30, COLORS.GRAY)};
    }
    #item-image {
      box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.08);
    }
  }

  ${media.tabletXLarge`
    padding: 0 34px;
    width: 33.3%;
  `}

  ${media.desktopLarge`
    width: 25%;
  `}

  ${(props: { kiosk: string | null }) => props.kiosk ? (
    media.desktopLarge`
      width: 33.3%;
    `) : (
    media.desktopLarge`
      width: 25%;
    `)
  }
`;

export const StyledProductLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  height: 100%;

  :hover {
    border-bottom: 1px solid ${darken(0.30, COLORS.LIGHT_GRAY)};
  }
`;

export const StyledItemLabel = styled.h1`
  color: ${COLORS.RED};
  font-family: ${FONTS.SECONDARY};
  font-size: 20px;
  font-weight: normal;
  -webkit-text-stroke: 0.65px;
  padding-bottom: 10px;
`;

export const StyledAllergensLabel = styled.h2`
  color: ${COLORS.GRAY};
  font-family: ${FONTS.SECONDARY};
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 30px;
`;

export const StyledMenuLoading = styled.div`
  transition: all 500ms linear;
  align-items: center;
  background: ${COLORS.BODY};
  display: flex;
  font-size: 32px;
  height: calc(100vh - ${LAYOUT.HEADER.M_HEIGHT}px);
  justify-content: center;
  left: 0px;
  opacity: ${(props: { loading: boolean }) => props.loading ? 1 : 0};
  position: fixed;
  top: ${LAYOUT.HEADER.M_HEIGHT}px;
  visibility: ${(props: { loading: boolean }) => props.loading ? 'visible' : 'hidden'};
  width: 100%;

  img {
    width: 100px;
  }

  ${media.tabletXLarge`
    height: calc(100vh - ${LAYOUT.HEADER.T_HEIGHT}px);
    top: ${LAYOUT.HEADER.T_HEIGHT}px;
  `}

  ${media.desktopLarge`
    height: calc(100vh - ${LAYOUT.HEADER.D_HEIGHT}px);
    top: ${LAYOUT.HEADER.D_HEIGHT}px;
  `}
`;
