import { AnyAction, Reducer } from 'redux';

// Actions
import filterActions from '../filters/actions';
import actions from './actions';

export interface ReducerState {
  filterOverlayVisible: boolean;
  searchVisible: boolean;
}
const initialState: ReducerState = {
  filterOverlayVisible: false,
  searchVisible: false,
};

export const globalReducer: Reducer<ReducerState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FILTER_OVERLAY_VISIBLE:
      return Object.assign({}, state, {
        filterOverlayVisible: action.visible,
      });
    case actions.SEARCH_VISIBLE:
      return Object.assign({}, state, {
        searchVisible: action.visible,
      });

    // Filter actions affecting global redux
    case filterActions.SET_FILTER_RESULTS:
      return Object.assign({}, state, {
        filterOverlayVisible: false,
      });
    default:
      return state;
  }
};
