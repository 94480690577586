import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

// Styles
import { Container, ContainerWrapper, StyledCategoryLink } from './styles';

// Utils
import { pathParams } from '../../constants/routes';

type StoreProps = {
  menu: any;
};

type PassProps = {
  // TODO - extract into type
  matchParams: {
    [key: string]: string;
  };
};

class MenuNav extends React.Component<StoreProps & PassProps> {
  renderCategories = (matchParams: any) => {
    const occasionId = matchParams[pathParams.occasionId];
    const catId = matchParams[pathParams.categoryId];
    const { menu } = this.props;

    if (menu && menu.length > 0) {
      const categoriesObj = _.filter(menu, (occasion: any) => occasion.safe_name === occasionId)[0];
      const foundCategories = _.get(categoriesObj, 'categories');
      return (foundCategories && foundCategories
        .map((category: any) => (
          <StyledCategoryLink
            to={`/o/${occasionId}/c/${category.external_id}`}
            key={`menu-nav-${category.external_id}`}
            active={catId === category.external_id}
            data-cy={catId === category.external_id ? 'submenu-active' : 'submenu-inactive'}
          >
            <span data-cy={category.name}>{category.name}</span>
          </StyledCategoryLink>
        ))
      );
    }
  }

  render() {
    return (
        <ContainerWrapper>
          <Container data-cy="subcategories">
            {this.renderCategories(this.props.matchParams)}
          </Container>
        </ContainerWrapper>
    );
  }
}

const mapStateToProps = state => ({
  menu: state.menu.data,
  navState: state.nav,
});

export default connect(mapStateToProps)(MenuNav);
