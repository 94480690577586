// Generic Service
import axios from 'axios';

// Requests a URL, returning a promise
export default function genericHttpService(options) {
  return axios(options)
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('error.response.data', error.response.data);
        console.log('error.response.status', error.response.status);
        console.log('error.response.headers', error.response.headers);

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('error.request', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error in request settings', error.message);
      }
      console.log('error.config', error.config);
      throw error;
    });
}

/**
 * Basic URIEncode helper
 *
 * @return {string}
 */
export const URIEncode = (params = {}) => (
  Object.keys(params)
    .filter(k => params[k] !== undefined && params[k] !== null && params[k].length)
    .map(k => {
      const result = Array.isArray(params[k]) ? params[k].join('|') : params[k];
      return `${encodeURIComponent(k)}=${encodeURIComponent(result)}`;
    })
    .join('&')
);
