import React from 'react';

// Routes
import { routes } from '../../constants/routes';

// Styles
import { StyledImageContainer, StyledLogo, StyledTextContainer, StyledWelcomeContainer } from './styles';

export const Welcome = () => (
  <StyledWelcomeContainer to={routes.menu.base}>
    <div>
      <StyledLogo
        src="/img/logo.png"
        alt="Pret A Manger"
        data-cy="logo"
      />
      <StyledImageContainer data-cy="splash-image">
        <img
          alt="Smashed Beets at Pret"
          role="presentation"
          src="/img/smashed_beets.jpg"
        />
      </StyledImageContainer>
      <StyledTextContainer>
        <div>
          <h2 data-cy="title">
            <span>Hello!</span>
          </h2>
          <h1 data-cy="message">Explore Pret's menu and our wide range of ingredients.</h1>
          <p data-cy="menu-link">This Way &rarr;</p>
        </div>
      </StyledTextContainer>
    </div>
  </StyledWelcomeContainer>
);
