import FocusTrap from 'focus-trap-react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import globalActions from '../../redux/global/actions';
// Utils
import { fireGaEvent, gaActions, gaCategories } from '../../utils/analytics';
// Styles
import {
    StyledCloseButton, StyledOverlay, StyledOverlayContent, StyledOverlayHeader,
} from './styles';

interface PassProps {
  children: any;
}

interface FilterProps {
  filterOverlayVisible: boolean;
  toggleFilterOverlay: (visible: boolean) => void;
}

const FilterOverlay = ({ filterOverlayVisible, children, toggleFilterOverlay }: PassProps & FilterProps) => {
  // Lock the body scroll when the filter overlay is open
  useEffect(() => {
    document.body.style.overflowY = filterOverlayVisible ? 'hidden' : 'scroll';
    window.addEventListener('keydown', close);

    return () => {
      window.removeEventListener('keydown', close);
    };
  }, [filterOverlayVisible]);

  const close = (evt: { keyCode: number }) => {
    // Close filter overlay on 'esc' press
    if (filterOverlayVisible && evt.keyCode === 27) {
      closeFilterOverlay();
    }
  };

  const closeFilterOverlay = () => {
    toggleFilterOverlay(!filterOverlayVisible);
    fireGaEvent(gaCategories.filterOverlay, gaActions.closeFilterOverlay, { label: 'From Filter Overlay' });
  };

  return (
    <>
      {filterOverlayVisible && (
        <FocusTrap>
          <StyledOverlay>
            <StyledOverlayHeader>
              <StyledCloseButton
                onClick={() => closeFilterOverlay()}
                data-cy="filter-dismiss"
              >
                <img
                  role="presentation"
                  alt="Close Icon"
                  src="/img/icons/close.svg"
                />
                <span>Filters</span>
              </StyledCloseButton>
            </StyledOverlayHeader>
            <StyledOverlayContent id="styled-overlay-content">
              {children}
            </StyledOverlayContent>
          </StyledOverlay>
        </FocusTrap>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  filterOverlayVisible: state.global.filterOverlayVisible,
});

const mapDispatchToProps = dispatch => ({
  toggleFilterOverlay: (visible: boolean) => dispatch(globalActions.toggleFilterOverlay(visible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterOverlay);
