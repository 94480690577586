import { ConnectedRouter } from 'connected-react-router';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import Analytics from '../components/Analytics';
import GlobalStyles from '../components/GlobalStyles';
// Routes
import { routes } from '../constants/routes';
// History
import { history } from '../redux/store';
// Route Containers
import InactivityHandler from '../routes/handlers/InactivityHandler';
import Menu from './Menu';
import Product from './Product';
import Search from './Search';
import Splash from './Splash';

class AppContainer extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <GlobalStyles />
        <Switch>
          {/* Splash Routes */}
          <Route exact path={routes.splash.base} component={props => <Splash supressRedirect={true} {...props} />} />

          {/* Menu Routes */}
          <Route exact path={routes.menu.base} component={Menu} />
          <Route exact path={routes.menu.occasion.base} component={Menu} />
          <Route exact path={routes.menu.occasion.category} component={Menu} />

          {/* Product Routes */}
          <Route path={routes.product.base} component={props => <Product supressRedirect={true} {...props} />} />
          <Route exact path={routes.product.verbose} component={props => <Product supressRedirect={true} {...props} />} />

          {/* Search */}
          <Route exact path={routes.search.base} component={props => <Search supressRedirect={true} {...props} />} />
        </Switch>
        <InactivityHandler />
        <Analytics />
      </ConnectedRouter>
    );
  }
}

export default AppContainer;
