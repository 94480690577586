import filter from 'lodash/filter';
import get from 'lodash/get';
import React from 'react';

// Constants
import { pathParams } from '../../constants/routes';
// Styles
import { StyledNoResults } from '../../styles/mixins';
// Utils
import { resizeImage } from '../../utils/imageHelper';
import { determineShowIcon, interpolateProductPath } from '../../utils/menu';
import {
    StyledAllergensLabel, StyledDietaryIcon, StyledItemCardContainer, StyledItemImage,
    StyledItemLabel, StyledMenuGrid, StyledMenuGridContainer, StyledMenuLoading, StyledNewIcon, StyledProductLink,
} from './styles';

type PassProps = {
  item: any;
  to: string;
};

export const MenuItemCard = ({ item, to }: PassProps) => {
  const kiosk = localStorage.getItem('kiosk');

  return (
    <StyledItemCardContainer data-cy="menu-item" kiosk={kiosk}>
      <StyledProductLink
        to={to}
      >
      <StyledItemImage id="item-image" backgroundUrl={resizeImage(item.image_url, 400, 400)} data-cy="image">
        {(() => {
          return item.is_new && (<StyledNewIcon
              alt="New"
              title="New"
              src={`/img/icons/new.png`}
              data-cy="new-icon"/>);
        })()}
      </StyledItemImage>
      <StyledItemLabel data-cy="name">
        {item.name}
      </StyledItemLabel>
        {(() => {
          const icon = determineShowIcon(item);
          return icon && (
            <StyledDietaryIcon
              alt={icon}
              title={icon}
              src={`/img/icons/${icon}.svg`}
              data-cy={icon}
            />
          );
        })()}
      <StyledAllergensLabel data-cy="allergens">
        {item.allergens_description}
      </StyledAllergensLabel>
      </StyledProductLink>
    </StyledItemCardContainer>
  );
};

type GridPassProps = {
  matchParams: {
    [key: string]: string;
  };
  menu: any;
  menuLoading: boolean;
};

const MenuGrid = ({ matchParams, menu, menuLoading }: GridPassProps) => {
  const occasionId = matchParams[pathParams.occasionId];
  const catId = matchParams[pathParams.categoryId];
  let selectedProducts: any = [];

  const renderMenu = () => {
    if (menu && menu.length > 0 && occasionId && catId) {
      const selectedOccasionMenuCatsObj = filter(menu, (occasion: any) => occasion.safe_name === occasionId)[0];
      const selectedOccasionMenuCats = get(selectedOccasionMenuCatsObj, 'categories', []);
      const selectedProductOptions = filter(selectedOccasionMenuCats, (cat: any) => cat.external_id === catId)[0];
      selectedProducts = get(selectedProductOptions, 'products', []);
      return true;
    }

    return false;
  };

  return (
    <StyledMenuGridContainer>
      <StyledMenuGrid data-cy="menu-grid">
        <StyledMenuLoading
          loading={menuLoading}
        >
          <img
            role="presentation"
            alt="Loading Spinner"
            src="/img/icons/star_loader.gif"
            data-cy="loading"
          />
        </StyledMenuLoading>

        {!menuLoading && (
          // Results
          (renderMenu() && selectedProducts.length > 0) ? selectedProducts.map((item: any) => (
            <MenuItemCard
              key={item.external_id}
              item={item}
              to={interpolateProductPath(occasionId, catId, item.external_id)}
            />
          )) : (
            // No results
            <StyledNoResults>
              <h3 data-cy="no-results-header">None of our menu items fit this criteria</h3>
              <p data-cy="no-results-text">Try reducing the amount of filters</p>
            </StyledNoResults>
          ))
        }
      </StyledMenuGrid>
    </StyledMenuGridContainer>
  );
};

export default MenuGrid;
