import { AnyAction, Reducer } from 'redux';

// Actions
import filterActions from '../filters/actions';
import actions from './actions';

export interface InterfaceDiet {
  name: string;
  safe_name: string;
}
export interface InterfaceAllergen {
  name: string;
  safe_name: string;
}
// @TODO type unfiltered menu once json struct is confirmed
export interface MenuItem {
  external_id: number;
  name: string;
  image: string;
  dietary_icon: string;
  allergens_description: string;
}
export interface Category {
  name: string;
  external_id: number;
  safe_name: string;
}
export interface ReducerState {
  data: any[];
  allergens: InterfaceAllergen[];
  dietaryFilters: InterfaceDiet[];
  ingredients: string[];
  loading: boolean;
  menuIndex: object;
}
const initialState: ReducerState = {
  data: [],
  allergens: [],
  dietaryFilters: [],
  ingredients: [],
  loading: false,
  menuIndex: {},
};

export const menuReducer: Reducer<ReducerState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_MENU:
    case filterActions.APPLY_FILTERS:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.FETCH_MENU_SUCCESS:
      return Object.assign({}, state, {
        data: action.menu,
        allergens: action.allergens,
        dietaryFilters: action.dietaryFilters,
        ingredients: action.ingredients,
        menuIndex: action.menuIndex,
        loading: false,
      });
    case actions.FETCH_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
};
