import _ from 'lodash';

// Routes
import { pathParams, routes } from '../constants/routes';

export const determineFirstCategoryId = (occasionName, menu) => {
  if (menu && menu.length > 0) {
    const occasion = menu.filter(item => item.safe_name === occasionName);
    if (occasion.length > 0) {
      const foundOccasion = occasion[0];
      const categories = _.get(foundOccasion, 'categories');
      const firstCategoryId = _.get(categories[0], 'external_id');
      return firstCategoryId;
    }
  }
};

export const determineShowIcon = (prod: any) => {
  if (prod.is_vegan) {
    return 'vegan';
  } else if (prod.is_vegetarian) {
    return 'vegetarian';
  } else {
    return '';
  }
};

export const interpolateMOCPath = (occasionId: string, categoryId: string) => {
  if (!occasionId || !categoryId) {
    return '';
  }

  return routes.menu.occasion.category.replace(`:${pathParams.occasionId}`, occasionId).replace(`:${pathParams.categoryId}`, categoryId);
};

export const interpolateProductPath = (occasionId: string, categoryId: string, productId: string) => {
  if (!occasionId || !categoryId || !productId) {
    return '';
  }

  return routes.product.verbose.replace(`:${pathParams.occasionId}`, occasionId).replace(`:${pathParams.categoryId}`, categoryId).replace(`:${pathParams.productId}`, productId);
};

export const deriveDefaultMenuRoute = (menu: any) => {
  if (!menu) {
    return routes.menu.base;
  }

  const occasionId = _.get(menu, '[0].safe_name');
  const categoryId = _.get(menu, '[0].categories[0].external_id');

  return interpolateMOCPath(occasionId, categoryId);
};

export const filterMenuByProductId = (menu, occasionName, categoryId, productId) => {
  let product = null;
  product = _
    .chain(menu)
    .filter(m => m.safe_name === occasionName)
    .first()
    .get('categories')
    .filter(c => c.external_id === categoryId)
    .first()
    .get('products')
    .filter(p => p.external_id === productId)
    .first()
    .value();
  return product;
};

// Returns an index of menu products where keys are product external ids
export const menuIndexByProductId = menu => {
  const productsArr: any[] = [];
  _.map(menu, m => {
    const categories = m.categories;
    const products = _.map(categories, c => c.products);
    productsArr.push(products);
  });
  const flattenedProducts = _.flattenDeep(productsArr);
  const transformed = _.reduce(flattenedProducts, (obj, p) => {
    obj[p.external_id] = p;
    return obj;
  },
  {});
  return transformed;
};
