import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import { MenuItemCard } from '../../components/MenuGrid';
// Constants
import { VERSION } from '../../constants/config';
// Routes
import { pathParams } from '../../constants/routes';
// Types
import { ReducerState as FiltersState } from '../../redux/filters/reducer';
// Actions
import searchActions from '../../redux/search/actions';
// Styles
import { StyledNoResults } from '../../styles/mixins';
// Utils
import { interpolateProductPath } from '../../utils/menu';
import { StyledMenuGrid, StyledMenuGridContainer, StyledMenuLoading } from '../MenuGrid/styles';

type StoreProps = {
  filters: FiltersState;
  results: any;
  searchMenu: (args: object) => void;
  menuLoading: boolean;
};

type PassProps = {
  // TODO - extract into type
  matchParams: {
    [key: string]: string;
  };
};

const SearchGrid = ({ filters, results, matchParams, menuLoading, searchMenu }: StoreProps & PassProps) => {
  useEffect(() => {
    const searchTerm = matchParams[pathParams.searchTerm];
    console.log('Performing search in SearchGrid', searchTerm);

    // Rudimentary debugging features
    if (searchTerm === 'fuzzversion') {
      alert(`Version ${VERSION}`);
    }

    if (searchTerm === 'fuzzkiosk') {
      window.location.href = '/app';
    }

    if (searchTerm === 'fuzzdesktop') {
      localStorage.removeItem('kiosk');
      window.location.href = '/';
    }

    const searchPayload = {
      keywords: [searchTerm],
      allergens: filters.allergens,
      diets: filters.diets,
      ingredients: filters.ingredients,
    };

    searchMenu(searchPayload);

  }, [matchParams]);

  return (
    <StyledMenuGridContainer>
      <StyledMenuGrid>
        <StyledMenuLoading
          loading={menuLoading}
        >
          <img
            role="presentation"
            alt="Loading Spinner"
            src="/img/icons/star_loader.gif"
          />
        </StyledMenuLoading>

        {!menuLoading && (
          (results.length ? results.map((item, index: number) => (
            <MenuItemCard
              key={`search-menu-item-card-${index}`}
              item={item}
              to={interpolateProductPath(item.menu_safe_name, item.category_external_id, item.external_id)}
            />
          )) : (
            <StyledNoResults>
              <h3 data-cy="no-results-header">None of our menu items fit this criteria</h3>
              <p data-cy="no-results-text">Try changing your search term or reducing the amount of filters</p>
            </StyledNoResults>
          )))}
      </StyledMenuGrid>
    </StyledMenuGridContainer>
  );
};

const mapStateToProps = state => ({
  filters: state.filters,
  menuLoading: state.search.loading,
  results: state.search.results,
});

const mapDispatchToProps = dispatch => ({
  searchMenu: (args: object) => dispatch(searchActions.searchMenu(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchGrid);
