import React, { Component } from 'react';
import { compose } from 'redux';

// HOC
import menuHandler from '../handlers/menuHandler';
import pageHandler from '../handlers/pageHandler';

// Natural Components
import FilterOverlay from '../../components/FilterOverlay';
import FilterForm from '../../components/FilterOverlay/form';
import Header from '../../components/Header';
import ProductDetail from '../../components/ProductDetail';

interface PassProps {
  goBackOrRedirect: () => void;
  match: {
    // TODO - extract into type
    params: {
      [key: string]: string;
    };
  };
  menuIndex: object;
  menuLoading: boolean;
}
class Product extends Component<PassProps> {
  render() {
    return (
      <>
        <Header />
        <ProductDetail
          goBack={this.props.goBackOrRedirect}
          matchParams={this.props.match.params}
          menuIndex={this.props.menuIndex}
          menuLoading={this.props.menuLoading}
        />
        <FilterOverlay>
          <FilterForm pushToMenu={true} />
        </FilterOverlay>
      </>
    );
  }
}

export default compose(
  menuHandler,
  pageHandler,
)(Product);
