import 'react-app-polyfill/ie11';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import FastClick from 'react-fastclick-alt';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// Redux Store
import configureStore from './redux/store';
// Component
import AppRouter from './routes';
// Service Worker
import * as serviceWorker from './serviceWorker';

// Initialize store
const store = configureStore();

ReactDOM.render(
  <FastClick>
    <Provider store={store}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Provider>
  </FastClick>, document.getElementById('root') as HTMLElement);

serviceWorker.unregister();

if (window.Cypress) {
  window.store = store;
}
