import get from 'lodash/get';
import { AnyAction } from 'redux';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

// Actions
import actions from './actions';

// Services
import { filterProducts } from '../../services/search.service';

export function* searchMenuSaga(action: AnyAction) {
  try {
    const flattenedArgs = Object.assign({}, action.args, {
      flat: ['true'],
    });

    const response = yield call(filterProducts, flattenedArgs);

    // Extract search keyword from args
    const searchTerm = get(action.args, 'keywords[0]', '');

    // Set search results
    yield put(actions.setSearchResults(searchTerm, response.data.products));
  } catch (error) {
    // Error Notification
    console.log('error in searchMenu saga', error);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.SEARCH_MENU, searchMenuSaga);
}
