import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Natural Components
import FilterOverlay from '../../components/FilterOverlay';
import FilterForm from '../../components/FilterOverlay/form';
import FilterStatus from '../../components/FilterStatus';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SearchGrid from '../../components/SearchGrid';
import SearchStatus from '../../components/SearchStatus';
// Routes
import { pathParams } from '../../constants/routes';
// Handlers
import menuHandler from '../handlers/menuHandler';
import pageHandler from '../handlers/pageHandler';

type PassProps = {
  goBackOrRedirect: () => void;
  match: {
    // TODO - extract into type
    params: {
      [key: string]: string;
    };
  };
};

type StoreProps = {
  searchResults: any;
  menuLoading: boolean;
};
class Search extends Component<PassProps & StoreProps> {
  render() {
    return (
      <>
        <Header />
        <SearchStatus
          searchTerm={this.props.match.params[pathParams.searchTerm]}
          goBack={this.props.goBackOrRedirect}
        />
        <FilterStatus redoSearch={true} />
        <SearchGrid matchParams={this.props.match.params} />
        <FilterOverlay>
          <FilterForm redoSearch={true} />
        </FilterOverlay>
        {this.props.searchResults.length > 0 && !this.props.menuLoading && localStorage.getItem('kiosk') === null && <Footer />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.search.results,
  menuLoading: state.search.loading,
});

export default compose(
  menuHandler,
  pageHandler,
  connect(mapStateToProps),
)(Search);
