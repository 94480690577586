import ENV_CONFIG from '../constants/env';

const API = {
  routes: {
    menu: '/catalog/UK/',
  },
};
// @TODO update after api is versioned
const ENV = {
  // url: `${ENV_CONFIG.REACT_APP_API_BASE_URL}/${ENV_CONFIG.REACT_APP_API_VERSION}`,
  url: `${ENV_CONFIG.REACT_APP_API_BASE_URL}`,
  scheme: ENV_CONFIG.REACT_APP_API_SCHEME,
  key: ENV_CONFIG.REACT_APP_API_KEY,
};

export default Object.assign({}, API, ENV);
