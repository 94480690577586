import { push } from 'connected-react-router';
import FocusTrap from 'focus-trap-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Routes
import { pathParams, routes } from '../../constants/routes';
// Utils
import { fireGaEvent, gaActions, gaCategories } from '../../utils/analytics';
// Styles
import {
    StyledCloseButton, StyledSearch, StyledSearchButton, StyledSearchContent, StyledSearchInput,
} from './styles';

interface SearchProps {
  closeSearch: (arg: boolean) => void;
  pushToSearch: (val: string) => void;
  searchLoading: boolean;
}

const SearchBar = ({ closeSearch, pushToSearch, searchLoading }: SearchProps) => {
  const [search, setSearch] = useState('');

  const sanitizeSearchTerms = (value: string) => {
    return value.replace(/[^A-Za-z0-9 ]/g, '');
  };

  const close = (evt: { keyCode: number }) => {
    // Close search bar on 'esc' press
    if (evt.keyCode === 27) {
      if (closeSearch) {
        closeSearch(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', close);

    return () => {
      window.removeEventListener('keydown', close);
    };
  }, []);

  return (
    <FocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        initialFocus: '#SearchBarInput',
      }}
    >
      <StyledSearch>
        <StyledSearchContent
          as="form"
          onSubmit={e => {
            e.preventDefault();
            pushToSearch(search);
            closeSearch(false);
            fireGaEvent(gaCategories.search, gaActions.performSearch, { label: search });
          }}
        >
          <StyledCloseButton
            aria-label="Close Search"
            onClick={() => closeSearch(false)}
            type="button"
            data-cy="search-dismiss"
          >
          {searchLoading ?
            <img
              role="presentation"
              src="/img/icons/search_loader.gif"
            /> :
              <img
              role="presentation"
              alt="Close Icon"
              src="/img/icons/close.svg"
            />
          }
          </StyledCloseButton>
          <StyledSearchInput
            id="SearchBarInput"
            onChange={e => setSearch(sanitizeSearchTerms(e.target.value))}
            placeholder="Search our menu"
            type="text"
            required={true}
            autoFocus={true}
            data-cy="search-field"
          />
          <StyledSearchButton
            aria-label="Submit Search"
            type="submit"
          >
            Search
          </StyledSearchButton>
        </StyledSearchContent>
      </StyledSearch>
    </FocusTrap>
  );
};

const mapStateToProps = state => ({
  searchTerm: state.search.term,
  searchLoading: state.search.loading,
});

const mapDispatchToProps = dispatch => ({
  pushToSearch: (searchValue: string) => searchValue ? dispatch(push(`${routes.search.base.replace(`:${pathParams.searchTerm}`, searchValue)}`)) : null,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
