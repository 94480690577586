import { AnyAction, Reducer } from 'redux';

// Actions
import actions from './actions';

export interface ReducerState {
  allergens: string[];
  diets: string[];
  ingredients: string[];
  loading: boolean;
}
const initialState: ReducerState = {
  // Client
  loading: false,
  allergens: [],
  diets: [],
  ingredients: [],

  // Results are pushed directly into the menu reducer...
};

export const filtersReducer: Reducer<ReducerState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.APPLY_FILTERS: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case actions.SET_FILTER_RESULTS: {
      return Object.assign({}, state, {
        allergens: action.values.allergens,
        diets: action.values.diets,
        ingredients: action.values.ingredients,
        loading: false,
      });
    }
    default:
      return state;
  }
};
