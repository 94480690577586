import { throttle } from 'frame-throttle';
import * as React from 'react';
// NOTE: location prop needed
import { withRouter } from 'react-router';

// Components
import GenericModal from '../../components/uielements/genericModal';
// Routes
import { routes } from '../../constants/routes';

type StoreProps = {
  location: any;
};

class InactivityHandler extends React.Component<StoreProps> {
  warningInterval: number = 60 * 1000;
  redirectInterval: number = 75 * 1000;

  warningHandle: any = null;
  redirectHandle: any = null;

  state = {
    warnTimerElapsed: false,
    openModal: false,
    firstLoad: true,
  };

  events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keydown',
    'keypress',
    'touchmove',
    'touchstart',
    'touchend',
  ];

  restartTimers = throttle(() => {
    console.log('reseting timer...');
    this.removeAllTimers();
    this.startTimers();
  });

  hideModal = () => {
    this.setState({
      openModal: false,
    });
    this.removeAllTimers();
    this.startTimers();
  }

  componentDidUpdate() {
    if (localStorage.getItem('kiosk') && this.state.firstLoad && this.props.location.pathname !== routes.splash.base) {
      this.activate();
      this.setState({
        firstLoad: false,
      });
    }
  }

  activate = () => {
    this.events.forEach(
      eventType => window.addEventListener(eventType, this.restartTimers));
  }

  // @TODO
  deactivate = () => {
    this.events.forEach(
      eventType => window.removeEventListener(eventType, this.restartTimers));
  }

  removeAllTimers = () => {
    if (this.warningHandle) {
      clearTimeout(this.warningHandle);
    }

    if (this.redirectHandle) {
      clearTimeout(this.redirectHandle);
    }
  }

  startTimers = () => {
    this.warningHandle = setTimeout(this.openModal, this.warningInterval);
    this.redirectHandle = setTimeout(this.redirect, this.redirectInterval);
  }

  openModal = () => {
    this.setState({
      openModal: true,
    });
  }

  redirect = () => {
    window.location.href = routes.splash.base;
  }

  render() {
    return (
      <>
        <GenericModal
          modalOpen={this.state.openModal}
          toggleModal={this.hideModal}
          cancelAction={this.redirect}
          confirmMessage="Continue"
          cancelMessage="Start Over"
        >
          <h2>
            <span>Do you need more time?</span>
          </h2>
        </GenericModal>
      </>
    );
  }
}

export default withRouter((InactivityHandler) as any);
