import styled from 'styled-components';

// Globals
import { COLORS, FONTS } from '../../styles';
// Breakpoints
import { media } from '../../styles/breakpoints';
import { ContainerBlock, deriveMediaQueriesFromHeaderHeight } from '../../styles/mixins';
// Components
import Tag from '../Tag';

export const StyledFilterStatusContainer = styled(ContainerBlock)`
  background: ${COLORS.WHITE};
  display: flex;
  flex-wrap: wrap;
  position: sticky;

  ${deriveMediaQueriesFromHeaderHeight('top: $s1$px;')}
`;

export const StyledFilterStatus = styled.div`
  border-top: 1px solid ${COLORS.LIGHT_GRAY};
  overflow: hidden;
  width: 100%;

  p {
    font-family: ${FONTS.PRIMARY};
    font-size: 18px;
    flex-shrink: 0;
    padding: 10px 20px 0 0;

    button {
      background: none;
      color: ${COLORS.RED};
      font-family: ${FONTS.SECONDARY};
      font-size: 14px;
      display: block;
      border: none;
      padding: 4px 0 0 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ${media.tabletXLarge`
    align-items: center;
    display: flex;

    p {
      padding-top: 0
    }
  `}
`;

export const StyledFilterTags = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  max-width: 100%;
`;

export const StyledTag = styled(Tag)`
  font-size: 14px;
  margin: 10px 10px 15px 0;
  max-width: 150px
  min-height: 38px;
  padding: 5px 10px;
  -webkit-text-stroke: 0.65px;
  flex-shrink: 0;
  width: 33.33%;

  ${media.tabletXLarge`
    margin-top: 15px;
    min-width: 120px;
    width: 20%;
  `}
`;

export const StyledMoreFilters = styled.div`
  flex: 1;
  height: 100%;
  margin-left: 2%;
  width: 400px;

  button {
    background: none;
    border: none;
    color: ${COLORS.RED};
    cursor: pointer;
    font-family: ${FONTS.PRIMARY};
    font-size: 14px;
    height: 100%;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }

    ${media.tabletXLarge`
      font-size: 18px;
    `}
  }
`;
