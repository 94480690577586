import { AnyAction, Reducer } from 'redux';

// Menu Actions
import menuActions from '../menu/actions';
// Search Actions
import actions from './actions';

export interface ReducerState {
  loading: boolean;
  results: any[];
  value: string;
}

const initialState: ReducerState = {
  // Client
  loading: false,
  value: '',

  // API
  results: [],
};

export const searchReducer: Reducer<ReducerState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SEARCH_MENU:
      return Object.assign({}, state, {
        loading: true,
        results: [], // TODO - Reset existing search results on new search. The UI isn't totally ideal here though...
      });
    case actions.SET_SEARCH_RESULTS:
      return Object.assign({}, state, {
        loading: false,
        value: action.value,
        results: action.results,
      });
    case menuActions.FETCH_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
};
