import React from 'react';

// Styles
import { StyledTag } from './styles';

interface TagProps {
  className?: string;
  isSelected: boolean;
  onClick: () => void;
  children: any;
  testId?: string;
}

const Tag = ({ children, className, isSelected, onClick, testId }: TagProps) => {
  return (
    <StyledTag
      className={className}
      isSelected={isSelected}
      onClick={onClick}
      type="button"
      data-cy={testId}
    >
      {children}
    </StyledTag>
  );
};

export default Tag;
